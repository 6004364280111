import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-fab-to-top',
  templateUrl: './fab-to-top.component.html',
  styleUrls: ['./fab-to-top.component.scss'],
})
export class FabToTopComponent implements OnInit {

  @Input()
  showFab = false;

  @Output()
  onClickFab = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {}

  clickFab(){
    this.onClickFab.emit(true);
  }

}
