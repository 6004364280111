<div  style="overflow: hidden;"  [ngClass]="{'sended-message-container': sendBy, 'received-message-container':  !sendBy}">
  <ion-item lines="none" class="chat-list-item font-ask"
            [ngClass]="{
        'loading-ask': message?.loading || false,
        'hasButtons':showButton,
        'confirmed-message': showConfirmMessage
        }">
    <ion-skeleton-text *ngIf="message?.loading || false" animated class="shimmer"></ion-skeleton-text>

    <ion-grid class="ask-grid ion-align-items-center ion-align-self-center">

      <div *ngIf="!sendBy && hasTopStatus" class="label-status flex margin">
        <div [innerText]="normalizedInfo?.home?.to?.text || ''"></div>
        <div [innerText]="message?.dare?.amount ? '$' + message?.dare?.amount: 'FREE'"></div>
      </div>
      <div *ngIf="sendBy && hasTopStatus" class="label-status flex margin">
        <div [innerText]="normalizedInfo?.home?.by?.text  || ''"></div>
        <div [innerText]="message?.dare?.amount ? '$' + message?.dare?.amount+' USD' : 'FREE'"></div>
      </div>

      <ion-row>
        <ion-col style="display: flex;align-items: center; padding-top: 32px; padding-bottom: 24px; place-content: center;">
          <ion-label *ngIf="message?.linkOptions?.hasLink" (click)="onClickLink($event, message)" [innerHTML]="message?.linkOptions?.linkText" class="ion-text-wrap font-ask" [ngClass]="{'blur-text': enableBlurText && !sendBy} "></ion-label>
          <ion-label (click)="onClickLink($event, message)" [innerText]="message?.dare?.name" class="ion-text-wrap font-ask" [ngClass]="{'blur-text': enableBlurText && !sendBy} "></ion-label>
        </ion-col>
      </ion-row>
      <ion-row *ngIf="showAmountChip" style="justify-content: center;">
        <div class="container-chip"> <!--To separate the elements just remove background from this class-->
          <ion-chip class="no-margin" [ngClass]="{'sended-ask': sendBy, 'receiver-ask':!sendBy}">
            <ion-label *ngIf="!sendBy">
              {{ normalizedInfo.home.to.badge }} <span [id]="'ask-countdown-' + message.messageId"></span>
            </ion-label>
            <ion-label *ngIf="sendBy">
              {{ normalizedInfo.home.by.badge }} <span [id]="'ask-countdown-' + message.messageId"></span>
            </ion-label>
          </ion-chip>
        </div>
      </ion-row>
    </ion-grid>
  </ion-item>
  <ion-item lines="none"
            *ngIf="normalizedInfo?.actions && (normalizedInfo?.allowedActionUser === (sendBy ? 'by' : 'to')) || normalizedInfo?.allowedActionUser === 'both'"
            class="actions-item separator-ask font-ask" [ngClass]="{
        'action-sended':sendBy,
        'action-received':!sendBy,
        'loading-ask': message?.loading
        }">
    <ion-skeleton-text *ngIf="message?.loading" animated class="shimmer"></ion-skeleton-text>
    <ion-grid class="grid-actions" [ngClass]="{'center':message?.actions?.length === 1}">
      <div>
        <div class="label-status-rule margin" *ngIf="sendBy && normalizedInfo?.home?.by?.ruleText || false">
          {{normalizedInfo?.home?.by?.ruleText || ''}}
        </div>
        <div class="label-status-rule margin" *ngIf="!sendBy && normalizedInfo?.home?.to?.ruleText || false">
          {{normalizedInfo?.home?.to?.ruleText || ''}}
        </div>
      </div>
      <ion-row class="flex-buttons">
        <div *ngFor="let action of message?.actions"
             [ngClass]="{'action-button-small': action.size === 'small','action-button-large': action.size === 'large' ,'action-button-big-large':action.size === 'big-large'}">
          <ion-button class="action-button"
                      [disabled]="action?.disabled"
                      [ngClass]="{'action-button-solid': action.fill === 'solid', 'action-button-outline': action.fill === 'outline'}"
                      [fill]="action.fill"
                      (click)="execute(action, message)"
                      *ngIf="(action?.user === 'to' && !sendBy) || (action?.user === 'by' && sendBy) || (message?.sendBy?.name === 'Truly' && action.user === 'to')">
            <ion-label class="ion-text-wrap" *ngIf="action.text">{{ action.text }}</ion-label>
            <ion-icon style="font-size: 21px; color:#080d2b" *ngIf="action?.icon" slot="end" [name]="action?.icon"></ion-icon>
          </ion-button>
        </div>
      </ion-row>
    </ion-grid>
  </ion-item>
</div>
