import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NavController} from '@ionic/angular';

@Component({
  selector: 'app-header-camera',
  templateUrl: './header-camera.component.html',
  styleUrls: ['./header-camera.component.scss'],
})
export class HeaderCameraComponent implements OnInit {

  constructor(
    private navCtrl: NavController,
  ) {
  }

  @Output()
  public close = new EventEmitter<string>();

  @Input()
  public count: string;

  @Input()
  public isNativeCamera: boolean;

  ngOnInit() {
  }


  goBack() {
    this.close.emit();
  }
}
