import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})

export class TagService {
  collectionName = 'tags';

  constructor(private firestore: AngularFirestore, private http: HttpClient) {}

  create(data) {
    return this.firestore.collection(this.collectionName).add(data);
  }

  getById(id: string) {
    return this.firestore.collection(this.collectionName).doc(id).ref.get();
  }
  getAllTagsByName(name: string, limit: number) {
    return this.firestore
      .collection(this.collectionName)
      .ref.where('tag', '>=', name?.toLowerCase())
      .where('tag', '<=', name?.toLowerCase() + '\uf8ff')
      .limit(limit)
      .get();
  }

  getByName(name: string, limit: number) {
    return this.firestore
      .collection(this.collectionName)
      .ref.where('tag', '==', name?.toLowerCase())
      .limit(limit)
      .get();
  }

  getTrendingByName(name: string, limit: number) {
    return this.firestore
      .collection(this.collectionName)
      .ref.where('tag', '>=', name?.toLowerCase())
      .where('tag', '<=', name?.toLowerCase() + '\uf8ff')
      .where('isTrending', '==', true)
      .limit(limit)
      .get();
  }


  update(id: string, data: any) {
    return this.firestore.collection(this.collectionName).doc(id).update(data);
  }

  getMyOwnTags(userId) {
    return this.firestore
      .collection(this.collectionName)
      .ref.where('idCreator', '==', userId)
      .get();
  }

  getAll() {
    return this.firestore.collection(this.collectionName).ref.limit(11).get();
  }
  getTrendingTags() {
    return this.firestore.collection(this.collectionName).ref.where('isTrending', '==', true).get();
  }
  async getVideoTags() {
    return this.firestore
      .collection(this.collectionName)
      .ref
      .where('isTagVideo', '==', true)
      .orderBy('order', 'desc')
      .get();
  }

  getTagsByArrayApi(ids: any): any {
    return new Promise((resolve) => {
      this.http
        .post(`${environment.cloudFunctionUrl}/tag/get/byArray`, {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          ids,
        })
        .subscribe((data) => resolve(data));
    });
  }

  searchTags(tag) {
    return this.firestore
      .collection(this.collectionName).ref
      .where('tag', '>=', tag.toLowerCase())
      .where('tag', '<=', tag.toLowerCase() + '\uf8ff')
      .limit(50)
      .get();
  }

  getTrendingTag() {
    return this.firestore
      .collection(this.collectionName)
      .ref
      .orderBy('order', 'asc')
      .where('isTrending', '==', true)
      .get();
  }

  getSubTags(idMainTag) {
    return this.firestore
      .collection(this.collectionName)
      .ref
      .where('idMainTag', '==', idMainTag)
      .get();
  }


  getTagById(id) {
    return this.firestore
      .collection(this.collectionName)
      .doc(id)
      .ref
      .get();
  }
}
