import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {DefaultUtil} from '../../utils/default.util';
import {NavController} from '@ionic/angular';


@Component({
  selector: 'app-profile-tags',
  templateUrl: './profile-tags.component.html',
  styleUrls: ['./profile-tags.component.scss'],
})
export class ProfileTagsComponent implements OnInit {

  @Input()
  public chunkedtags: Array<any>;

  @Input()
  isTrending = false;

  @Input()
  isLoading = false;

  @Input()
  enableSelection = false;



  @Input()
  marginLeft = '16px';

  @Input()
  loadQuatityRow = 3;

  @Input()
  midSize = false;

  @Input()
  grayTag = false;

  @Output()
  public onClickTag = new EventEmitter();

  public loadChunkedtags: Array<any>;

  constructor(private defaultUtils: DefaultUtil) {
    this.chunkedtags = [];
  }

  ngOnInit() {
    this.mountLoader();
  }

  select(tag,indexRow, indexTag) {
    this.onClickTag.emit(tag);
    if (this.enableSelection) {
      this.chunkedtags[indexRow][indexTag].selected = !this.chunkedtags[indexRow][indexTag].selected;
    };
  }

  mountLoader() {
    const array = new Array(15);
    const max = 90;
    const min = 150;
    for (const a of array.keys()) {
      array[a] = Math.floor(Math.random() * (max - min + 1)) + min;
    }
    this.loadChunkedtags = this.defaultUtils.chunkArray(array, this.loadQuatityRow);
  }

}
