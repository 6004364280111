<ion-content>
<!--  <app-header-default [customBack]="true" (onBack)="close()" ></app-header-default>-->
  <div class="ctn-header-default">
    <ion-row class="ctn-row">
      <div class="container-icon" (click)="close()">
        <ion-icon class="icon"  name="arrow-back-outline"></ion-icon>
      </div>
      <div class="container-icon">
      </div>
      <div class="container-icon">
      </div>
    </ion-row>
  </div>
  <pinch-zoom style="    width: 100%; height: 100%;">
    <div class="image" [style.background]="'url(' +url+ ')'"></div>
  </pinch-zoom>
</ion-content>

