import { Component, OnInit } from '@angular/core';
import {ModalController} from '@ionic/angular';
import {Router} from '@angular/router';
import {EmitEventService} from '../../services/emit-event.service';

@Component({
  selector: 'app-modal-info-onboarding',
  templateUrl: './modal-info-onboarding.page.html',
  styleUrls: ['./modal-info-onboarding.page.scss'],
})
export class ModalInfoOnboardingPage implements OnInit {

  constructor(private modalCtrl: ModalController,
              private router: Router,
              private emitEventService: EmitEventService) { }

  ngOnInit() {
  }

  start() {
    this.close();
    this.router.navigateByUrl('edit-profile');
  }

  close() {
    this.emitEventService.pusblishFirstEditProfile(false);
    this.modalCtrl.dismiss();
  }

}
