import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DefaultUtil} from '../../../utils/default.util';

@Component({
  selector: 'app-thumb-ask-video',
  templateUrl: './thumb-ask-video.component.html',
  styleUrls: ['./thumb-ask-video.component.scss'],
})
export class ThumbAskVideoComponent implements OnInit {

  @Input()
  hasTopStatus: boolean;

  @Input()
  sendBy: boolean;

  @Input()
  showButton: boolean;

  @Input()
  normalizedInfo: any;

  @Input()
  message: any;

  @Input()
  showConfirmMessage: boolean;

  @Input()
  showAmountChip: boolean;

  @Output()
  public executeAction = new EventEmitter();

  @Output()
  public onClickPlayVideo = new EventEmitter();

  @Output()
  public onClickInternalLink = new EventEmitter();

  isPending = false;

  orientation: 'cover' | 'contain' = 'contain';


  constructor(public defaultUtil: DefaultUtil) {
  }

  async ngOnInit() {
    this.actionPedingVideo();
   this.orientation = await this.verifyimageOrientation(this.message?.dare?.dareMedia?.imageUrl);
  }

  execute(action, data) {
    this.executeAction.emit({action, data});
  }

  play(message) {
    this.onClickPlayVideo.emit(message);
  }

  validateRating(status) {
    const valid = [
      'confirmed',
      'autoconfirmed',
      'trialconfirmwon',
    ].some((a) => a === status);
    return (valid && this.sendBy);
  }

  actionPedingVideo() {
    let count = 0;
    const interval = setInterval(async () => {
      this.isPending = !(await this.defaultUtil.validFileExist(this.message?.dare?.dareMedia?.imageUrl));
      count++;
      if (!this.isPending || count === 200) {
        this.isPending = false;
        clearInterval(interval);
      }
    }, 1000);
  }

  onClickLink(event, message) {
    if (event?.target?.className === 'fake-link' && message?.linkOptions?.userWithLink?.id) {
      this.onClickInternalLink.emit(message?.linkOptions?.userWithLink);
    }
  }

  getImageInfo(url) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.onerror = (err) => reject(err);
      img.src = url;
    });
  }

 async verifyimageOrientation(src) {
    const img: any = await this.getImageInfo(src);
    if (img?.naturalWidth > img?.naturalHeight) {
      return 'cover';
    }
    return 'contain';
  }
}
