<div class="ctn-header-default" [ngClass]="{'top-native-camera':isNativeCamera}">
  <ion-row class="ctn-row">
    <div class="container-icon" (click)="goBack()">
      <ion-icon class="icon"  name="arrow-back-outline"></ion-icon>
    </div>
    <div class="container-icon">
      <ion-label>{{ count }}</ion-label>
    </div>
    <div class="container-icon">
    </div>
  </ion-row>
</div>
