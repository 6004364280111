import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Tag} from '../../models/tag';
import {TagService} from '../../services/tag.service';
import {Pages} from '../../app-routing.module';
import {NavigationExtras, Router} from '@angular/router';
import {PreviousRouteService} from '../../services/previous-route.service';
import {parseHostBindings} from '@angular/compiler';
import {DefaultUtil} from '../../utils/default.util';
import {environment} from '../../../environments/environment';
import {AnalyticsUtil} from '../../utils/analytics.util';
import {PushHelper} from '../../helpers/push.helper';
import {AuthService} from '../../services/auth.service';
import {UserService} from '../../services/user.service';
import {AlertController, NavController} from '@ionic/angular';
import {EmitEventService} from '../../services/emit-event.service';

@Component({
  selector: 'app-home-view',
  templateUrl: './home-view.component.html',
  styleUrls: ['./home-view.component.scss'],
})
export class HomeViewComponent  implements OnInit {

  @Input()
  hasCateg: boolean;

  @Input()
  pageName: string;

  @Input()
  simpleHeader: boolean;

  @Input()
  fixMenu: boolean;

  @Input()
  backButtonMobile: boolean;

  @Input()
  showSideMenu = true;

  @Input()
  customBack = false;

  @Input()
  hideHeader: boolean;

  @Output()
  private onClickBack = new EventEmitter<any>();


  categories: Array<any>;

  user: any;

  constructor(private tagService: TagService,
              private router: Router,
              private defaultUtil: DefaultUtil,
              private analyticsUtil: AnalyticsUtil,
              private pushHelper: PushHelper,
              private userService: UserService,
              private authService: AuthService,
              private alertController: AlertController,
              private emitService: EmitEventService,
              private navCtrl: NavController,
  ) { }

  async ngOnInit() {
    this.getCategories();
    this.user = await this.defaultUtil.getUser();
  }


  async getCategories() {
    const isLargeScreen = window.innerWidth > 700;
    this.categories = (await this.tagService.getTrendingTag()).docs?.map((a, index) => {
      const data: Tag = a?.data() as Tag;
      if (isLargeScreen && index === 8 || !isLargeScreen && index === 5) {
        data.category = {
          name: 'More',
          enable: true,
          icon: 'ellipsis-horizontal',
        };
      }
      return {
        id: a?.id,
        ...data,
      };
    })?.filter((a, index) => isLargeScreen ? index <= 8 : index <= 5);
  }

  async onClickCateg(categ) {
    this.emitService.pusblishChoseCategHome(categ);
  }

   navigateToChatList() {
      this.router.navigate(['tabs/chat-list']);
  }

   navigateToHome() {
    this.router.navigate(['tabs/home']);
  }

  navigateToMyProfile() {
    this.emitService.pusblishUpdateReviewProfile(true);
    this.router.navigate(['tabs/my-profile']);
  }

  navigateToCoachProfile() {
    this.router.navigate(['tabs/coach-settings']);
  }

  navigateToWithdraw() {
    this.router.navigate(['tabs/withdraw']);
  }

  navigateToMore() {
    this.router.navigate(['tabs/more']);
  }

  navigateToVideoList() {
    this.router.navigate(['tabs/video-list']);
  }

  navigateToTerms() {
    this.router.navigate(['terms']);
  }

  async shareProfile()  {
    const user = await this.defaultUtil.getUser();
    const defaultUrl = environment.type === 'dev' ? 'jointruly.app' : 'jointruly.com';
    const shareData = {
      text: 'Ask me on Truly!\n',
      url: `https://${defaultUrl}/${user?.name}`,
    };
    await this.defaultUtil.share(shareData, null, null, user);
  }

  async actionLogout() {
    const alert = await this.alertController.create({
      header: 'Logout',
      message: 'Are you sure?',
      buttons: [
        {
          text: 'NO',
        },
        {
          text: 'YES',
          handler: () => this.doLogout(),
        },
      ],
    });
    await alert.present();
  }

  redirectToEmail() {
    window.open('mailto:hello@jointruly.com', '_blank');
  }


  async doLogout() {
    this.analyticsUtil.logoutAnalytics();
    this.userService.resetUser();
    await this.pushHelper.removeUserInfo();
    await this.router.navigateByUrl('/login', {replaceUrl: true});
    await this.authService.logout();
  }

  async modalGeneralSearch() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        originPage: `home`,
      },
    };
    this.router.navigate(['modal-general-search'],navigationExtras);
  }

  goBack() {
    if(this.customBack){
      this.onClickBack.emit();
      return;
    }
      this.navCtrl.back();
  }

  hasErrorVerify() {
    if(this.user?.isVerified) {
      return false;
    }else if(this.user?.verifyAccount?.type === 'not approved'){
      return true;
    }
    return this.user?.verifyAccount?.type === 'processing';
  }

  async onCoachSettingsClick() {
    if (this.user?.isVerified) {
     await this.router.navigate(['tabs/coach-settings']);
    } else {
      if(this.hasErrorVerify()){
        return await this.onCoachSettingsFailed();
      }
      const alert = await this.alertController.create({
        header: 'Access the Coach Portal',
        message: 'You need to verify your account before proceeding. We’ll redirect you to Stripe for secure verification.',
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
          },
          {
            text: 'Verify Now',
            handler: () => this.defaultUtil.verifyIdentity(`${window.location.origin}/tabs/coach-settings`),
          }
        ]
      });

      await alert.present();
    }
  }

  async onCoachSettingsFailed() {
    const alert = await this.alertController.create({
      header: 'Verification Failed',
      message: this.user?.verifyAccount?.reason,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Try again',
          handler: () => this.defaultUtil.verifyIdentity(window.location.href),
        }
      ]
    });

    await alert.present();
  }

}
