import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DefaultUtil} from '../../../utils/default.util';
import {ChatService} from '../../../services/chat.service';
import {EmitEventService} from '../../../services/emit-event.service';
import {ModalController} from '@ionic/angular';
import {PhotoViewerPage} from '../../../pages/photo-viewer/photo-viewer.page';
import {NotificationService} from '../../../services/notification.service';

@Component({
  selector: 'app-thumb-media',
  templateUrl: './thumb-media.component.html',
  styleUrls: ['./thumb-media.component.scss'],
})
export class ThumbMediaComponent implements OnInit {
  @Output()
  public onClickPlayVideo = new EventEmitter();

  @Output()
  public onClickInternalLink = new EventEmitter();

  @Output()
  public onClickFile = new EventEmitter();

  @Input()
  sendBy: boolean;

  @Input()
  message: any;

  @Input()
  chatId: any;

  uploadInterval: any;


  progress = {
    type: 'indeterminate',
    value: ''
  };


  constructor(public defaultUtil: DefaultUtil,
              private chatService: ChatService,
              private emitEventService: EmitEventService,
              private modalCtrl: ModalController,
              private notificationService: NotificationService) {
  }

  ngOnInit() {
    this.initFunctions();
  }

  listenUpload() {
    this.emitEventService.getObservableDataUpload()
      .subscribe((data) => {
        this.setProgressDownload(data);
      });
  }

  initFunctions() {
    this.listenUpload();
    if (this.message?.media?.verifyImage) {
      this.validateImageThumb();
      this.setProgressDownload(null);
    }
    if (this.message.type === 'upload') {
      if (this.message?.imagePreview) {
        this.defaultUtil.uploadImageFromChat(this.message, this.message.chatId);
      }
      if (this.message?.videoPreview) {
        this.defaultUtil.uploadVideoFromChat(
          this.message,
          this.message.videoPreview?.blob,
          this.message.imagePreview?.blob,
          this.message.chatId);
      }
      if (this.message?.filePreview) {
        this.defaultUtil.uploadFileFromChat(
          this.message,
          this.message.chatId
        );
      }
    }
  }

  setProgressDownload(value) {
    if (value) {
      this.progress.type = '';
      this.progress.value = `${value / 100}`;
    } else {
      this.progress.type = 'indeterminate';
      this.progress.value = `${value / 100}`;
    }
  }


  validateImageThumb() {
    let count = 0;
    this.uploadInterval = setInterval(async () => {
      const fileExist = await this.defaultUtil.validFileExist(this.message?.media?.imageUrl);
      count++;
      if (fileExist) {
        await this.defaultUtil.timeout(400);
        clearInterval(this.uploadInterval);
        this.message.media.verifyImage = false;
        delete this.message.linkOptions;
        await this.chatService.updateChat(this.message, this.chatId, this.message.messageId);
      }
      if (count === 20) {
        clearInterval(this.uploadInterval);
      }
    }, 2000);
  }

  play(message) {
    this.onClickPlayVideo.emit(message);
  }

  async openModalPhotoViewer(url) {
    if (this.message?.type === 'video') {
      return;
    }
    const modal = await this.modalCtrl.create({
      component: PhotoViewerPage,
      componentProps: {
        url
      },
      swipeToClose: true,
      backdropDismiss: true,
    });
    modal.present();
  }

  onClickLink(event, message) {
    if (event?.target?.className === 'fake-link' && message?.linkOptions?.userWithLink?.id) {
      this.onClickInternalLink.emit(message?.linkOptions?.userWithLink);
    }
  }

  clickFile(message) {
    this.onClickFile.emit(message?.media);
  }

}
