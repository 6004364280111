import {Injectable} from '@angular/core';
import {DefaultUtil} from './default.util';

@Injectable({
  providedIn: 'root',
})
export class AskUtil {
  constructor(public defaultUtils: DefaultUtil) {

  }

  validateRating(rating, sendToUserName) {
    if (rating) {
      if (rating?.message) {
        return `"${rating?.message}"`;
      } else {
        return `Thank you!`;
      }
    }
    return `Please rate ${sendToUserName}`;
  }

  validateObjectLinkReview(rating) {
    if (!rating) {return null;}
    else if (rating?.message) {return null;}
    else {
      return {
        user: 'by',
        text: 'Write a review',
        status: 'review',
      };
    }
  }

  normalizeAskStatus(status, sendByUserName, sendToUserName, shared = null, rated = null) {
    switch (status) {
      case 'dareverifyrejected':
        return {text: 'Blocked by admin', actions: []};
      case 'accepted':
        return {
          text: 'Accepted',
          home: {
            to: {
              badge: ``,
              text: 'IN PROGRESS / EARN',
            },
            by: {
              badge: `Timer:`,
              text: `IN PROGRESS`,
            },
          },
          allowedActionUser: 'to',
          actions: [
            {
              user: 'to',
              text: 'RECORD',
              status: 'record',
              color: '#080d2b',
              fill: 'solid',
              size: 'large',
              icon: 'videocam-outline',
            },
          ],
        };
      case 'sent':
        return {
          text: 'New consultation',
          home: {
            badge: true,
            to: {
              // badge: `Timer:`,
              badge: ``,
              text: `${this.defaultUtils.getNameFromUnloggedAsk(sendByUserName)} sent you an ask`,
              // text: `New question`,
              //text: `YOU GOT ASK`,
            },
            by: {
              badge: ``,
              text: 'SENT',
            },
          },
          allowedActionUser: 'to',
          actions: [
            // {
            //   user: 'to',
            //   text: 'Decline', //reject
            //   status: 'chicken',
            //   color: '#FFFFFF',
            //   fill: 'outline',
            //   size: 'small',
            //   icon: null,
            // },
            {
              user: 'to',
              text:
              // 'OPEN',
              // 'ACCEPT',
              //accept
                'RESPOND WITH VIDEO',
              status: 'accepted',
              color: '#FFFFFF',
              fill: 'solid',
              size: 'large',
              icon: null,
            },
          ],
        };
      case 'confirm':
        return {
          text: 'Completed',
          home: {
            badge: true,
            to: {
              // badge: `Waiting on ${sendByUserName}`,
              badge: '',
              text: `WAITING CONFIRMATION`, // you sent a video response
              ruleText: ' ',  //review time - text label
              //ruleText: `${this.defaultUtils.getNameFromUnloggedAsk(sendByUserName) } has 24hrs to confirm.`,
              requestTimer: true,
            },
            by: {
              badge: ``,
              // text: `You got video!`,
              text: `Completed`,
              //ruleText: `Please Confirm`,
              requestTimer: true,
            },
          },
          allowedActionUser: 'both',
          actions: [
            {
              user: 'by',
              text: 'Contest',
              status: 'contest',
              color: '#FFFFFF',
              fill: 'outline',
              size: 'large',
              icon: null,
            },
            {
              user: 'by',
              text: 'Confirm',
              status: 'confirmed',
              color: '#FFFFFF',
              fill: 'solid',
              size: 'large',
              icon: null,
            },
          ],
        };
      case 'contested':
        return {
          text: 'Response contested',
          home: {
            badge: true,
            to: {
              //badge: `Timer: `, //Waiting for your response4b
              // text: `${sendByUserName} contested your video`,
              text: `24hrs to Respond`,
              //ruleText:` ${sendByUserName} contested your response.`,
              ruleText: `They contested your response.`,
              requestTimer: true,
            },
            by: {
              //badge: `Timer: `,
              // text: `You contested video`,
              text: `Contested`,
              ruleText: `They have 24hrs to either re-do or request a review. `,
              requestTimer: true,
            },
          },
          allowedActionUser: 'both',
          actions: [
            {
              user: 'to',
              text: 'Refund',
              status: 'contestedcancel',
              color: '#FFFFFF',
              fill: 'outline',
              size: 'small',
              icon: null,
            },
            {
              user: 'to',
              text: 'Redo',
              status: 'record',
              color: '#FFFFFF',
              fill: 'solid',
              size: 'large',
              icon: 'videocam-outline',
            },
            // {
            //   user: 'to',
            //   text: 'Trial',
            //   status: 'trial',
            //   color: '#FFFFFF',
            //   fill: 'outline',
            //   size: 'small',
            //   icon: null,
            // },
          ],
          link: {
            user: 'to',
            text: 'request review',
            status: 'trial',
          }
        };
      case 'autoconfirmed':
        return {
          text: 'Win',
          home: {
            to: {
              badge: `Autoconfirmed`,
              text: 'CONFIRMED',
            },
            by: {
              badge: `Autoconfirmed`,
              text: `Your consultation was confirmed`,
              ruleText: this.validateRating(rated, sendToUserName),
            },
          },
          allowedActionUser: 'both',
          actions: [
            {
              user: 'to',
              text: shared ? 'Unshare' : 'Share to Profile',
              // text: 'Shared',
              status: 'share',
              color: '#FFFFFF',
              fill: 'solid',
              size: 'big-large',
              icon: null,
              disabled: false
            },
          ],
          link: this.validateObjectLinkReview(rated)
        };
      case 'confirmed':
        return {
          text: 'Win',
          home: {
            to: {
              badge: `Confirmed`,
              //text: `${sendByUserName} confirmed your Ask`,
              text: `CONFIRMED`,
            },
            by: {
              badge: `Confirmed`,
              text: 'Confirmed',
              ruleText: this.validateRating(rated, sendToUserName),
            },
          },
          allowedActionUser: 'both',
          actions: [
            {
              user: 'to',
              text: shared ? 'Unshare' : 'Share to Profile',
              status: 'share',
              color: '#FFFFFF',
              fill: 'solid',
              size: 'big-large',
              icon: null,
              disabled: false
            },
          ],
          link: this.validateObjectLinkReview(rated)
        };
      case 'taken_time_expired':
      case 'accept_expired':
        return {
          text: 'Time-Expired',
          home: {
            badge: false,
            to: {
              badge: 'Expired',
              text: 'Expired',
            },
            by: {
              badge: `Expired`,
              text: `Expired`,
            },
          },
        };
      case 'ignore':
        return {
          text: 'Declined',
          home: {
            to: {
              badge: `Ignore`,
              text: 'Ignore',
            },
            by: {
              badge: `Ignore`,
              text: `Ignore`,
            },
          },
        };
      case 'chicken':
        return {
          text: 'Declined',
          home: {
            to: {
              badge: `Declined`,
              text: 'Declined',
            },
            by: {
              badge: `Declined`,
              text: `Declined`,
            },
          },
        };
      case 'trial':
        return {
          text: 'Sent to trial',
          home: {
            to: {
              badge: `In Review`,
              //text: 'Waiting for trial result',
              text: 'Waiting for Review',
              ruleText: 'Your review request is in process.',
              requestTimer: true,
            },
            by: {
              badge: `In Review`,
              //text: 'Waiting for trial result',
              text: `Waiting for Review`,
              ruleText: `They requested a review.`,
              requestTimer: true,
            },
          },
          allowedActionUser: 'both',
          actions: [],
        };
      case 'trialconfirmwon':
        return {
          text: 'Win (Decided in trial)',
          home: {
            to: {
              badge: `Waiting on ${sendByUserName}`,
              text: 'You won in trial',
              ruleText: 'Win (Decided in trial)',
            },
            by: {
              badge: `Your turn`,
              text: `You lost in trial`,
              ruleText: rated ? `Thank you!`
                : `You lost in trial, please rate ${sendToUserName}`,
            },
          },
          allowedActionUser: 'both',
          actions: [
            {
              user: 'to',
              text: shared ? 'Unshare' : 'Share to Profile',
              // text:'Shared',
              status: 'share',
              color: '#FFFFFF',
              fill: 'solid',
              size: 'big-large',
              icon: null,
              disabled: false
            },
          ],
        };
      case 'trialconfirmlost':
        return {
          text: 'Rejected in trial',
          home: {
            to: {
              // badge: `Waiting on ${sendByUserName}`,
              badge: `You lost in trial`,
              text: `You lost in trial`,
            },
            by: {
              badge: `You won in trial and`,
              text: 'You won in trial and',
            },
          },
        };
      case 'cancelled':
        return {
          text: 'Cancelled',
          home: {
            to: {
              badge: `Waiting on ${sendByUserName}`,
              text: 'You accepted',
            },
            by: {
              badge: `Your turn`,
              text: `They opened`,
            },
          },
        };
      case 'cancelledbysender':
        return {
          text: 'Cancelled by sender',
          home: {
            to: {
              badge: `Waiting on ${sendByUserName}`,
              text: 'You accepted',
            },
            by: {
              badge: `Your turn`,
              text: `They opened your inquiry`,
            },
          },
        };
      case 'contestedcancel':
        return {
          text: 'Cancelled by player',
          home: {
            to: {
              badge: `Cancelled by player`,
              //text: `You canceled the Ask and\ntoken has been refunded to ${sendByUserName}'s account.`,
              text: `Refunded`,
              ruleText: `Cancelled`,

            },
            by: {
              badge: `Cancelled by player`,
              text: 'REFUNDED',
              ruleText: `Cancelled by ${sendToUserName}.`,

            },
          },
          allowedActionUser: 'both',
          actions: [],
        };
      case 'taken_time_expired':
        return {
          text: 'Time-Expired',
          badge: false,
          home: {
            to: {
              text: 'Expired',
            },
            by: {
              text: `Expired`,
            },
          },
        };
      default:
        return status;
    }
  }

  normalizeAskStatusVideoCall(status, sendByUserName, sendToUserName, shared = null, rated = null) {
    switch (status) {
      case 'sent':
        return {
          text: 'New consultation',
          home: {
            badge: true,
            to: {
              // badge: `Timer:`,
              badge: ``,
              //text: `${sendByUserName} sent you an ask`,
              text: `NEW REQUEST SESSION`,
              //text: `YOU GOT ASK`,
            },
            by: {
              badge: ``,
              text: 'LIVE CALL CONFIRMED',
              //text: 'REQUEST SESSION SENT',
            },
          },
          allowedActionUser: 'to',
          actions: [
            {
              user: 'to',
              text: 'DECLINE', //reject
              status: 'rejected',
              color: '#FFFFFF',
              fill: 'outline',
              size: 'small',
              icon: null,
            },
            {
              user: 'to',
              text: 'ACCEPT',
              status: 'accepted',
              color: '#FFFFFF',
              fill: 'solid',
              size: 'large',
              icon: null,
            },
          ],
        };
      case 'accepted':
        return {
          text: 'New Video Call',
          home: {
            badge: true,
            to: {
              badge: ``,
              text: `NEW VIDEO CALL`,
            },
            by: {
              badge: ``,
              text: `NEW VIDEO CALL`,
            },
          },
          allowedActionUser: 'both',
          actions: [
            {
              user: 'to',
              text: 'JOIN THE MEETING',
              status: 'join',
              color: '#FFFFFF',
              fill: 'solid',
              size: 'large',
              icon: '',
            },
            {
              user: 'by',
              text: 'JOIN THE MEETING',
              status: 'join',
              color: '#FFFFFF',
              fill: 'solid',
              size: 'large',
              icon: '',
            },
          ],
        };
      case 'rejected':
        return {
          text: 'Declined',
          home: {
            to: {
              badge: `Declined`,
              text: 'Declined',
            },
            by: {
              badge: `Declined`,
              text: `Declined`,
            },
          },
        };
      default:
        return {text: 'error', actions: []};
    }
  }


  getAskTimer(status) {
    switch (status) {
      case 'sent':
        return 86400000;
      // return 90000;
      case 'accepted':
        return 86400000;
      // return 90000;
      case 'confirm':
        return 86400000;
      // return 90000;
      case 'contested':
        return 86400000;
      // return 90000;
      default:
        return 0;
    }
    // sent: 90000,
    // accepted: 90000,
    // confirm: 90000,
    // contested: 90000
  }

  // getActionsByStatus(status) {
  //   switch (status) {
  //     case: ''
  //   }
  // }

  validateTopStatus(status) {
    switch (status) {
      case 'confirmed':
        return 'confirm';
      case 'trial':
        return 'confirm';
      case 'contested':
        return 'confirm';
      case 'contestedcancel':
        return 'confirm';
    }
    return status;

  }

  validateBottomStatus(status) {
    switch (status) {
      case 'confirmed':
        return '';
    }
    return status;
  }
}
