import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {EmitEventService} from './emit-event.service';
import {AngularFirestore} from '@angular/fire/firestore';
import {environment} from '../../environments/environment';
import firebase from 'firebase/app';
import 'firebase/storage';

@Injectable({
  providedIn: 'root'
})
export class MediaService {
  convertVideoCollection = 'videosToConvert';
  constructor(
    private http: HttpClient,
    private emitEventService: EmitEventService,
    private firestore: AngularFirestore
) {
  }

  uploadFileFirebase(path: string, file: Blob): any {
    const storage = firebase.storage();
    return new Promise(resolve => {
      const metadata = {
        contentType: file.type
      };
      const uploadTask = storage
        .ref(path)
        .put(file, metadata);
      uploadTask.then((fileSnapshot) => {
        fileSnapshot.ref.getDownloadURL().then(url => {
          resolve(url);
        });
      }).catch(error=>{
        console.log('editBackground uploadFileFirebase >>>>>>>>>',error);
      });
      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED,
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          this.emitEventService.publishDataUploadProgress(Math.round(progress));
        },
        (error) => {
          resolve({success: false, payload: error});
        },
        () => {
        }
      );
    });
  }

  async fileExist(url: string) {
    const result = await fetch(url, {
      method: 'GET',
    });
    return result.status;
  }

 async getConvertedVideo(url) {
    return this.firestore
      .collection(this.convertVideoCollection)
      .ref.where('url', '==', url)
      .limit(1)
      .get();
  }

  async setConvertedVideo(data) {
    return this.firestore.collection(this.convertVideoCollection).add(data);
  }

  getFramesFromVideo(url: any): any {
    return new Promise((resolve) => {
      this.http
        .post(`${environment.cloudFunctionUrl}/media/get/frames`, {url})
        .subscribe(value => resolve(value), error => {resolve([]);});
    });
  }

  async getBLobUrl( url ) {
    const response = await fetch( url, {
      method: 'GET',
    } );
    return response.blob();
  }
}
