import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RatingsService {
  collectionName = 'ratings';

  constructor(private firestore: AngularFirestore, private http: HttpClient) {}

  getChatRating(chatId) {
    return this.firestore
      .collection(this.collectionName)
      .ref.where('ask.chatId', '==', chatId)
      .get();
  }

  getChatRatingByIds(chatId,messageId) {
    return this.firestore
      .collection(this.collectionName)
      .ref.where('ask.chatId', '==', chatId)
      .where('ask.messageId', '==', messageId)
      .get();
  }

  getChatRatingByUser(userId) {
    return this.firestore
      .collection(this.collectionName)
      .ref.where('userReference', '==', userId)
      .orderBy('createdAt', 'desc')
      .get();
  }

  saveRating(rate) {
    return this.firestore.collection(this.collectionName).add(rate);
  }

  update(id: string, data: any) {
    return this.firestore.collection(this.collectionName).doc(id).update(data);
  }
}
