import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-thumb-message',
  templateUrl: './thumb-message.component.html',
  styleUrls: ['./thumb-message.component.scss'],
})
export class ThumbMessageComponent implements OnInit {

  @Input()
  sendBy: boolean;

  @Input()
  message: any;

  @Output()
  public onClickInternalLink = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
  }

  onClickLink(event, message) {
    if (event?.target?.className === 'fake-link' && message?.linkOptions?.userWithLink?.id) {
      this.onClickInternalLink.emit(message?.linkOptions?.userWithLink);
    }
  }
}

