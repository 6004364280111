import {EventEmitter, Injectable} from '@angular/core';
import {Feed} from '../models/feed.model';

@Injectable({
  providedIn: 'root'
})
export class EmitEventService {

  private entersHome = new EventEmitter();
  private uploadProgress = new EventEmitter();
  private updateFeed = new EventEmitter();
  private updateEditedVideo = new EventEmitter();
  private updateMyProfile = new EventEmitter();
  private submitComment = new EventEmitter();
  private firstEdit = new EventEmitter();
  private closeModal = new EventEmitter();
  private openTipModal = new EventEmitter();
  private cleanBackgroundCrop = new EventEmitter();
  private enterMyProfile = new EventEmitter();
  private addTagsTrending = new EventEmitter();
  private openSendAskModal = new EventEmitter();
  private openSendResquestModal = new EventEmitter();
  private choseCategHome = new EventEmitter();
  private changePage = new EventEmitter();
  private updateReviewProfile = new EventEmitter();

  publishIonViewDidEnterHome(data: any) {
    this.entersHome.next(data);
  }

  getObservableIonViewDidEnterHome() {
    return this.entersHome;
  }


  publishDataUploadProgress(data) {
    this.uploadProgress.next(data);
  }

  getObservableDataUpload() {
    return this.uploadProgress;
  }


  publishDataUpdateFeedData(data: { page: string; value: any }) {
    this.updateFeed.next(data);
  }

  getObservableUpdateFeedData() {
    return this.updateFeed;
  }

  publishDataUpdateEditedVideo(data: boolean) {
    this.updateEditedVideo.next(data);
  }

  getObservableEditedVideo() {
    return this.updateEditedVideo;
  }

  publishUpdateMyprofile(data) {
    this.updateMyProfile.next(data);
  }

  getObservableUpdateMyprofile() {
    return this.updateMyProfile;
  }


  pusblishSubmitComment(data) {
    this.submitComment.next(data);
  }

  getObservableSubmitComment() {
    return this.submitComment;
  }


  pusblishFirstEditProfile(data) {
    this.firstEdit.next(data);
  }

  getObservableFirstEditProfile() {
    return this.firstEdit;
  }

  pusblishCloseModal(data) {
    this.closeModal.next(data);
  }

  getObservableCloseModal() {
    return this.closeModal;
  }

  pusblishOpenTipModal(data) {
    this.openTipModal.next(data);
  }

  getObservableOpenTipModal() {
    return this.openTipModal;
  }

  publishCleanBackgroudCrop(data) {
    this.cleanBackgroundCrop.next(data);
  }

  getCleanBackgroudCrop() {
    return this.cleanBackgroundCrop;
  }

  publishIonViewDidEnterMyProfile(data: any) {
    this.enterMyProfile.next(data);
  }

  getObservableIonViewDidEnterMyProfile() {
    return this.enterMyProfile;
  }

  publishTagsTrending(data: any) {
    this.addTagsTrending.next(data);
  }

  getObservableTagsTrending() {
    return this.addTagsTrending;
  }

  pusblishOpenSendAskModal(data, isSession = false) {
    this.openSendAskModal.next({data, isSession});
  }

  getObservableOpenSendAskModal() {
    return this.openSendAskModal;
  }

  pusblishChoseCategHome(data) {
    this.choseCategHome.next(data);
  }

  getObservableChoseCategHome() {
    return this.choseCategHome;
  }

  pusblishChangePage(data) {
    this.changePage.next(data);
  }

  getChangePage() {
    return this.changePage;
  }

  pusblishUpdateReviewProfile(data) {
    this.updateReviewProfile.next(data);
  }

  getUpdateReviewProfile() {
    return this.updateReviewProfile;
  }

}
