
<div class="container">
  <div class="player" [ngStyle]="{visibility : this.controlView.showVideo ? 'visible' : 'hidden' }">
    <div class="container-load-video" *ngIf="played === 0">
      <ion-icon  *ngIf="!isLoadVideo && showPlay" (click)="play()" style="color:#fff; width: 50px;height: 50px" name="play"></ion-icon>
      <ion-spinner *ngIf="isLoadVideo && !isIos" class="spinner" color="light"></ion-spinner>
    </div>
    <div [id]="'video'+this.index"></div>
  </div>
  <div class="loader" *ngIf="this.controlView.showPending"></div>
  <div class="container-reupload" *ngIf="this.controlView.showReupload">
    <ion-icon class="icon-warn" name="warning-outline"></ion-icon>
    <div class="message">There was an error while uploading your video. Please try again.</div>
    <button class="reupload-btn" (click)="onClickReupload()">Re-Upload</button>
  </div>
</div>

