// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  type: 'prod',
  customDepositId: 'prod_KE9dVaMR4Uy0tN',
  zenDeskLinkRedirect: 'https://jointruly.zendesk.com',
  cloudFunctionUrl: 'https://us-central1-truly-production-24c15.cloudfunctions.net/accessFunction',
  firebaseIdentity: 'https://identitytoolkit.googleapis.com/v1',
  calendly:{
    url:'https://api.calendly.com',
    auth:'https://auth.calendly.com',
    clientId:'81lXMLslzyXHLxc_zXdyRU6PWjJ1xMlOrDl-DGcCSwg',
    clientSecret:'8rX9_6HlpduXE0Bk1QWyFBXnQCLdrNNCgaKwK46bX84',
    webhookSiginKey:'Iipu9ngUfmbkOjTFXLb-fi4EeMlx_kFDRqsVAjzmX44',
    redirectUrl:'https://jointruly.com/edit-profile-price'
  },
  firebaseConfig: {
    apiKey: 'AIzaSyBHRITSvmj2OkZxs0Grwbq4hYWFqZolNDM',
    authDomain: 'truly-production-24c15.firebaseapp.com',
    projectId: 'truly-production-24c15',
    storageBucket: 'truly-production-24c15.appspot.com',
    messagingSenderId: '125863294613',
    appId: '1:125863294613:web:86a2252f02b0d7ad8a0d9c',
    measurementId: '${config.measurementId}'
  },
  adminPhotoURL: 'https://firebasestorage.googleapis.com/v0/b/gorodchalski.appspot.com/o/truly_images%2Fu-icon120%402x.png?alt=media&token=0e5f38e9-bded-4298-bbeb-8a37a144d9b7',
  adminName: 'Truly',
  addpipe: {
    accountHash: '8428ddeeb20b24c1d3dd51e7b370415e',
    eid: 'O6DsSS',
  },
  stripe: {
    publicKey: 'STRIPE_PUBLIC_KEY="pk_live_51J6IhzHMOejDPMZyrm1928rfqgVsBqcoqZcrjGQMoHxILNpkmGJ3bYsiu1pRbHU3GR4zD071rhgC1qx52N9ggFcG00vHSMSloS"\n',
  },
  limitFileSizeUpload:50,
  oneSignalAppID:'4157217f-5f79-486f-ad95-e6349728f68f',
  defaulAccentColorNotification:'4343EE',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.


