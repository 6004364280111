import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Feed, Like, Comment} from '../../models/feed.model';
import {DefaultUtil} from '../../utils/default.util';
import {PlatformUtil} from '../../utils/platform.util';
import {ButtonOption, OptionIconType} from '../../pages/modal-options-default/modal-options-default.page';
import {environment} from '../../../environments/environment';
import {FeedService} from '../../services/feed.service';
import {NavigationExtras, Router} from '@angular/router';
import {SendAskPage} from '../../pages/send-ask/send-ask.page';
import {ModalController} from '@ionic/angular';
import {EmitEventService} from '../../services/emit-event.service';
import {UserService} from '../../services/user.service';
import {User} from '../../models/user';
import {ColorToast} from '../../enums/color-toast';
import {ReportService} from '../../services/report.service';
import {ModalContestPage} from '../../pages/modal-contest/modal-contest.page';
import {OptionVideo} from '../thumb-video-list/thumb-video-list.component';
import {NotificationService} from '../../services/notification.service';

export enum OptionModal {
  report = 'Report',
  share = 'Share',
  ask = 'Consult',
  edit = 'Add thumbnail',
  close = 'Close',
  makeVideoVisible = 'Make video visible',
  hideVideo = 'Unshare',
  pinVideo = 'Pin to the top',
  unPinVideo = 'Unpin',
}

@Component({
  selector: 'app-post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.scss'],
})

export class PostComponent implements OnInit {

  @Input()
  public feed: Feed;

  @Input()
  removeFirstBorder = false;

  @Input()
  optionAsk = false;

  @Input()
  optionEdit = false;

  @Input()
  optionReport = true;

  @Input()
  loggedUser = null;

  @Input()
  limmitSearch = null;

  @Input()
  customId = '';

  @Input()
  index = '';

  @Input()
  showComment = false;

  @Input()
  showActions = true;

  @Input()
  origin = '';

  @Input()
  showMoreButton = true;

  @Output()
  onClickProfile = new EventEmitter<any>();

  @Input()
  showPinnedLabel = false;

  @Input()
  isMyProfile = false;

  showAllText = false;
  textDesc = 'more';
  textOptions = null;

  time = '';

  listButtonFilter: Array<ButtonOption>;

  likeId = null;
  likes = [];

  comments = [];
  isLoadComments = false;
  enableAnimation = false;

  text = '';
  limit = 1000;

  public userOwner: User;

  constructor(private defaultUtil: DefaultUtil,
              private platformUtil: PlatformUtil,
              private feedService: FeedService,
              private router: Router,
              private modalCtrl: ModalController,
              private emitEventService: EmitEventService,
              private userService: UserService,
              private notificationService: NotificationService,
              private reportService: ReportService) {
  }

  async ngOnInit() {
    const maxLetters = this.platformUtil.isDesktop() ? 20 : 16;
    this.limit = this.origin === 'watch' ? this.limit : 3;
    // eslint-disable-next-line max-len
    this.textOptions = this.defaultUtil.validTextMore(this.feed?.media?.name, 3, maxLetters);
    this.time = this.defaultUtil.fromNow(this.feed.createdAt);
    if (this.loggedUser?.id) {
      this.getLikes();
      if (this.showComment) {
        this.getComments();
      }
    }
    const {payload} = await this.userService.getUserByEmailorUsername(this.feed?.user?.name);
    this.userOwner = payload;
  }


  setTextMode() {
    if (this.showAllText) {
      this.textOptions.text = this.textOptions?.shortText;
      this.textDesc = 'more';
    } else {
      this.textDesc = 'less';
      this.textOptions.text = this.textOptions?.largeText;
    }
    if (!this.textOptions?.shortText) {
      this.textOptions.text = this.textOptions?.largeText;
    }
    this.showAllText = !this.showAllText;
  }

  clickProfile(userName) {
    this.onClickProfile.emit(userName);
  }

  async modalOptions() {
    try {
      this.listButtonFilter = [];
      if (this.optionReport) {
        this.listButtonFilter.push({
          text: OptionModal.report,
          selected: false,
          icon: 'flag-outline',
          disable: false,
          iconType: OptionIconType.ionicIcon,
        });
      }
      if (this.optionAsk && this.feed?.user?.id !== this.loggedUser?.id) {
        this.listButtonFilter.unshift({
          text: OptionModal.ask,
          selected: false,
          icon: 'chatbubble-ellipses-outline',
          disable: false,
          iconType: OptionIconType.ionicIcon,
        });
      }

      if (this.isMyProfile) {
        this.listButtonFilter.unshift(
          {
            text: this.feed?.pinnedAt ? OptionVideo.unPinVideo : OptionVideo.pinVideo,
            selected: false,
            icon: '../../../assets/images/pin_filled.png',
            iconType: OptionIconType.image,
          });
      }

      if (this.optionEdit) {
        this.listButtonFilter.unshift({
          text: OptionModal.edit,
          selected: false,
          icon: 'pencil',
          disable: false,
          iconType: OptionIconType.ionicIcon,
        });
        this.listButtonFilter.unshift({
          text: this.feed.isPrivate ? OptionModal.makeVideoVisible : OptionModal.hideVideo,
          selected: false,
          icon: this.feed.isPrivate ? 'eye-off' : 'eye',
          disable: false,
          iconType: OptionIconType.ionicIcon,
        });
      }

      const response = await this.defaultUtil.modalOptionsDefault({
        listButtons: this.listButtonFilter,
        titleCloseLabel: OptionModal.close,
        title: 'Options',
      });
      this.onSelectOption(response);
    } catch (e) {
      console.log('e', e);
    }
  }

  onSelectOption(response) {
    switch (response) {
      case OptionModal.ask:
        return this.presentModalAsk();
      case OptionModal.report:
        return this.modalContestReport();
      case OptionModal.edit:
        return this.navigateToEdit();
      case  OptionModal.makeVideoVisible:
      case  OptionModal.hideVideo:
        return this.shareProofs();
      case OptionVideo.pinVideo:
      case  OptionVideo.unPinVideo:
        return this.pinVideoAction();
      case OptionModal.close:
        return true;
    }
  }

  async shareProofs() {
    if (!this.feed?.owner) {
      return;
    }
    this.feed.isPrivate = !this.feed.isPrivate;
    this.feed.updatedAt = this.defaultUtil.getDefaultDateFirebase();
    this.feedService.updatePostFeed({
      isPrivate: this.feed.isPrivate,
      updatedAt: this.feed.updatedAt
    }, this.feed.id);
    this.defaultUtil.setUpdateMyProfile();
    this.defaultUtil.globalToast(`Private video.`, ColorToast.primary);
  }

  async shareVideo() {
    let defaultUrl = window.location.href.split('/')[2];
    if (this.platformUtil.isCordova()) {
      defaultUrl = environment.type === 'dev' ? 'jointruly.app' : 'jointruly.com';
    }
    const shareData = {
      text: 'Check my Video!\n',
      url: `https://${defaultUrl}/watch?ref=${this.feed.id}`,
    };
    await this.defaultUtil.share(shareData, null, null, null, true, 'Video link copied.<br>Anyone can watch without an account.');
  }

  navigateToEdit() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        id: this.feed.id,
      },
    };
    this.router.navigate(['edit-video'], navigationExtras);
  }


  async presentModalAsk(message = null) {
    this.modalCtrl.dismiss();
    const modal = await this.modalCtrl.create({
      component: SendAskPage,
      cssClass: 'small-modal',
      componentProps: {
        selectedUser: this.userOwner || this.feed.user,
        fromProfile: true,
        unloggedUser: !(this.loggedUser?.id),
        question: message || '',
      },
      backdropDismiss: true,
    });
    return await modal.present();
  }

  controlLike() {
    if (!this.loggedUser?.id) {
      this.navigateToLogin();
      return;
    }
    if (this.likeId) {
      return this.removeLike();
    }
    return this.addLike();
  }

  validLikeIcon() {
    if (this.likeId) {
      return 'assets/icon/like_filled.svg#Layer_1';
    }
    return 'assets/icon/like.svg#Layer_1';
  }

  close() {
    this.modalCtrl.dismiss();
  }


  async addLike() {
    this.enableAnimation = true;
    const data: Like = {
      createdAt: this.defaultUtil.getDefaultDateFirebase(),
      user: {
        id: this.loggedUser.id,
        photo: this.loggedUser.photoURL,
        name: this.loggedUser.name,
        email: this.loggedUser.email,
      },
    };
    if (this.feed.quantityLikes) {
      this.feed.quantityLikes += 1;
    } else {
      this.feed.quantityLikes = 1;
    }
    setTimeout(() => {
      this.enableAnimation = false;
    }, 1000);

    this.likeId = 'like';
    this.feedService.updateCountLikes(this.feed.id, 1);
    this.likeId = (await this.feedService.saveLike(this.feed.id, data))?.id;
    if (this.feed.user.id === this.loggedUser.id) {
      return;
    }
    await this.notificationService.createNotificationAllDevices({
      localNotification: {
        username: this.loggedUser.name,
        avatar: this.loggedUser.photoURL,
        sendTo: {
          photo: this.userOwner.photoURL,
          email: this.userOwner.email,
          name: this.userOwner.name,
          id: this.userOwner.id,
        },
        sendBy: {
          photo: this.loggedUser.photoURL,
          email: this.loggedUser.email,
          name: this.loggedUser.name,
          id: this.loggedUser.id,
        },
        msg: 'liked your video! 👍',
        type: 'feed',
        urlLink: '',
        readed: false,
        ref: this.feed.id,
      },
      pushNotification: {
        title: '',
        subTitle: `${this.loggedUser.name} liked your video! 👍`,
        email: this.userOwner.email,
        userName: this.userOwner.name,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        large_icon: this.loggedUser.photoURL,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        big_picture: '',
        params: {page: 'feed', ref: this.feed.id}
      },
    });
  }

  removeLike() {
    if (this.feed.quantityLikes) {
      this.feed.quantityLikes -= 1;
    }
    this.feedService.deleteLike(this.feed.id, this.likeId);
    this.feedService.updateCountLikes(this.feed.id, -1);
    this.likeId = null;
  }

  async getLikes() {
    this.likes = (await this.feedService.getLikes(this.feed?.id)).docs?.map(a => ({id: a?.id, ...a?.data() as Like}));
    this.likeId = this.likes?.find(a => a?.user?.id === this.loggedUser?.id)?.id;
  }

  onChangeTextarea(text) {
    this.text = text;
  }

  redirectToVideo() {
    this.router.navigateByUrl(`watch?ref=${this.feed.id}&initialScroll=${true}`);
  }

  navigateToLogin() {
    this.router.navigate([`login`]);
  }

  navigateToProfile(user) {
    this.router.navigate([`profile/${user?.name}`]);
  }

  controlLoad() {
    if (this.origin === 'watch') {
      this.loadMore();
    } else {
      this.redirectToVideo();
    }
  }

  setShowComment() {
    if (!this.loggedUser?.id) {
      this.navigateToLogin();
      return;
    }
    if (!this.showComment) {
      this.showComment = true;
      this.getComments();
    }
  }

  loadMore() {
  }

  async getComments() {
    this.isLoadComments = true;
    this.comments =
      (await this.feedService.getComments(this.feed?.id, this.limit)).docs?.map(a => ({id: a?.id, ...a?.data() as Comment}));
    this.isLoadComments = false;
  }

  addComment() {
    const data: Comment = {
      text: this.text?.trim(),
      createdAt: this.defaultUtil.getDefaultDateFirebase(),
      enable: true,
      user: {
        id: this.loggedUser.id,
        photo: this.loggedUser.photoURL,
        name: this.loggedUser.name,
        email: this.loggedUser.email,
      },
    };
    if (this.feed.quantityComments) {
      this.feed.quantityComments += 1;
    } else {
      this.feed.quantityComments = 1;
    }
    this.feedService.updateCountComment(this.feed.id, 1);
    this.feedService.saveComment(this.feed.id, data);
    this.text = '';
    this.emitEventService.pusblishSubmitComment(true);
    this.comments.unshift(data);
    if (this.feed.user.id === this.loggedUser.id) {
      return;
    }
    this.notificationService.createNotificationAllDevices({
      localNotification: {
        username: this.loggedUser.name,
        avatar: this.loggedUser.photoURL,
        sendTo: {
          photo: this.userOwner.photoURL,
          email: this.userOwner.email,
          name: this.userOwner.name,
          id: this.userOwner.id,
        },
        sendBy: {
          photo: this.loggedUser.photoURL,
          email: this.loggedUser.email,
          name: this.loggedUser.name,
          id: this.loggedUser.id,
        },
        msg: 'commented on your video! 💬',
        type: 'feed',
        urlLink: '',
        readed: false,
        ref: this.feed.id
      },
      pushNotification: {
        title: '',
        subTitle: `${this.loggedUser.name} commented on your video! 💬`,
        userName: this.userOwner.name,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        large_icon: this.loggedUser.photoURL,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        big_picture: '',
        params: {page: 'feed', ref: this.feed.id}
      },
    });
  }

  removeComment({index, commentId}) {
    if (this.feed.quantityComments) {
      this.feed.quantityComments -= 1;
    }
    this.feedService.deleteComment(this.feed.id, commentId);
    this.feedService.updateCountComment(this.feed.id, -1);
    this.comments.splice(index, 1);
    this.likeId = null;
  }

  async modalContestReport() {
    try {
      const exists: any = (await this.reportService.verifyExistsReport(this.feed.id, this.loggedUser.id))?.docs?.some(a => a?.exists);
      if (exists) {
        return this.defaultUtil.globalToast('Video has already been reported', ColorToast.danger);
      }
      const modal = await this.modalCtrl.create({
        component: ModalContestPage,
        swipeToClose: true,
        backdropDismiss: true,
        showBackdrop: true,
        mode: 'md',
        cssClass: 'contest-modal',
        componentProps: {
          titleModal: `Does this video contain inappropriate content?`,
          titleButton: 'Report it now',
          titleInput: 'Reason'
        },
      });
      await modal.present();
      const value = await modal.onDidDismiss();
      const reason = value?.data?.reason;
      if (reason) {
        this.reportVideo(reason);
      }
    } catch (e) {
      console.log('modalContestReport error', e);
    }
  }

  async reportVideo(reason) {
    const data = {
      createdAt: this.defaultUtil.getDefaultDateFirebase(),
      reportedBy: {id: this.loggedUser.id, name: this.loggedUser.name, photo: this.loggedUser.photoURL},
      feed: this.feed,
      reason,
    };
    await this.reportService.createReport(data);
    await this.updateReportCount();
    return this.defaultUtil.globalToast('Video reported', ColorToast.primary);
  }

  async updateReportCount() {
    const quantityReports: number = (await this.reportService.getAllReport(this.feed.id))?.docs?.length || 0;
    this.feedService.updatePostFeed({
      quantityReports
    }, this.feed.id);

  }

  async pinVideoAction() {
    if (!this.feed?.pinnedAt) {
      const valid = await this.validPinVideo();
      if (!valid) {
        return;
      }
    }
    const message = this.feed?.pinnedAt ? 'The video has been unpinned and may no longer appear in the top position of your feed.' :
      'Great news! This video has now secured the top spot in your feed.';

    const result = (await this.feedService.getPinFeeds(this.loggedUser?.id))?.docs?.map(a => ({id: a?.id, ...a?.data() as any}));
    for (const r of result) {
      await this.feedService.updatePostFeed({
        pinnedAt: this.defaultUtil.deleteFieldFirestore(),
      }, r.id);
    }
    if (!this.feed?.pinnedAt) {
      await this.feedService.updatePostFeed({
        pinnedAt: this.defaultUtil.getDefaultDateFirebase(),
        updatedAt: this.defaultUtil.getDefaultDateFirebase(),
      }, this.feed.id);
    }
    await this.defaultUtil.setUpdateMyProfile();
    this.emitEventService.publishIonViewDidEnterMyProfile(true);
    this.defaultUtil.globalToast(message, ColorToast.primary, 4000);
  }


  async validPinVideo() {
    return new Promise(async resolve => {
      const alert = {
        header: 'Pin this Post',
        message: 'This will will appear at the top of your profile and replace any previously pinned video. Are you sure?',
        buttons: [
          {
            text: 'NO',
            handler: () => resolve(false)
          },
          {
            text: 'YES',
            handler: () => resolve(true)
          }
        ]
      };
      await this.defaultUtil.alertDefault(alert);
    });
  }

}
