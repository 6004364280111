<ion-content>
  <div class="container">
    <ion-icon (click)="close(false)" class="icon" name="close-outline"></ion-icon>
    <div class="wrapper">
      <div class="title">Send Money</div>
      <div class="row">
        <app-profile-photo fontSize="20px" [borderLightGray]="true" [size]="{width: '50px', height: '50px'}"
                           [photoUrl]="userChat?.photoURL"
                           [userName]="userChat?.name"></app-profile-photo>
        <div class="title subtitle">{{userChat?.name}}</div>
      </div>
      <div class="row">
        <div style="margin-top: 20px"></div>
        <div class="title-money">${{valueRange}}</div>
        <div class="title-money subtitle">USD</div>
      </div>
      <div class="row">
        <ion-range style="width: 100%" [min]="1" [max]="100" [pin]="true" (ionChange)="onIonChange($event)">
          <div slot="start" class="title subtitle">1</div>
          <div slot="end" class="title subtitle">100</div>
        </ion-range>
      </div>
      <div class="ctr-button">
        <ion-button class="button" (click)="createTransfer()" *ngIf="!isLoad">
          <ion-label>Send</ion-label>
        </ion-button>
        <ion-spinner class="spinner-tip" *ngIf="isLoad"></ion-spinner>
      </div>
    </div>
  </div>
</ion-content>
