import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentReference } from '@angular/fire/firestore';


@Injectable({
  providedIn: 'root',
})
export class TrialService {
  collectionName = 'trials';

  constructor(private firestore: AngularFirestore) {}

  create(data) {
    return this.firestore.collection(this.collectionName).add(data);
  }

}
