<div class="center-thumb">
  <ion-chip *ngIf="sendBy" class="ask-amount-chip"
            style="background-color: #0095cf42; height: 60px; border-radius: 60px; padding: 40px;">
    <ion-label class="amount"
               style="color:#0f163d;font-weight: 500;font-size: 18px;letter-spacing: 1px;text-align: center;line-height: 24px;">
      You sent ${{ message?.amount }} tip
    </ion-label>
  </ion-chip>
  <ion-chip *ngIf="!sendBy" class="ask-amount-chip" style="background-color: #0095cf42; height: 60px; border-radius: 60px; padding: 40px;">
    <ion-label class="amount" style="color:#0f163d;font-weight: 500;font-size: 18px;letter-spacing: 1px;text-align: center;line-height: 24px;">
      You got ${{ message?.amount }} tip
    </ion-label>
  </ion-chip>
</div>
