<ion-content #contentSendRequestSession>
  <div class="send-request__header">
    <div class="send-request__header--ctn-user">
      <app-profile-photo style="margin-right: 6px" fontSize="12px"
                         [border]="true" [size]="{width: '50px', height: '50px'}"
                         [isVerified]="selectedUser?.isVerified"
                         [photoUrl]="selectedUser?.photoURL" [userName]="selectedUser?.name">
      </app-profile-photo>
      <div class="send-request__header--ctn-title">
        <div class="send-request__user-name">Book a Video Call</div>
        <div class="send-request__subtitle">{{ selectedUser.name }} | {{selectedUser?.price && selectedUser?.price?.value > 0 ? '$' + selectedUser?.price?.value : 'FREE'  }} Session</div>
      </div> 
    </div>
    <ion-icon (click)="closeModal()" class="send-request__close-icon" name="close"></ion-icon>
  </div>
  <div class="send-request__container-spinner" *ngIf="isload">
    <ion-spinner class="send-request__container-spinner--spinner"></ion-spinner>
  </div>
  <div [ngClass]="{'hidden':isload}" class="calendly-inline-widget send-request__ctn-calendy">
  </div>

  <div class="send-request__ctn-bottom">
    <!-- <div>
      <img width="50" src="../../../assets/images/stripe-logo.png" alt="Stripe Logo"/>
      <div>Powered by Stripe.</div>
    </div> -->
    <div style="margin-left: 16px">
      <img width="86" style="margin-right: 5px" src="../../../assets/images/calendly-logo.png" alt="Calendly Logo"/>
      <div>Powered by Calendly.</div>
    </div>
  </div>
</ion-content>
