<ion-fab class="fab"
         [ngClass]="{'show':showFab }"
         slot="fixed"
         vertical="bottom"
         horizontal="end"
         (click)="clickFab()">
  <ion-fab-button>
    <ion-icon name="arrow-up-outline"></ion-icon>
  </ion-fab-button>
</ion-fab>
