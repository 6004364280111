import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {DefaultUtil} from '../../utils/default.util';
import {ModalController} from '@ionic/angular';



export enum OptionIconType {
  svg = 'svg',
  image = 'image',
  ionicIcon = 'ionicIcon'
}
export interface ButtonOption {
  text: string;
  icon?: string;
  iconType?: OptionIconType;
  selected?: boolean;
  disable?: boolean;
}

export interface PropsModalDefault {
  listButtons: Array<ButtonOption>;
  title: string;
  titleCloseLabel: string;
}

@Component({
  selector: 'app-modal-options-default',
  templateUrl: './modal-options-default.page.html',
  styleUrls: ['./modal-options-default.page.scss'],
})
export class ModalOptionsDefaultPage implements OnInit {

  @Input()
  public props: PropsModalDefault;

  @ViewChild('modalOptionsBody', {read: ElementRef}) modalOptionsBody: ElementRef;
  @ViewChild('optionElement', {read: ElementRef}) optionElement: ElementRef;

  constructor(private defaultUtil: DefaultUtil,
              private modalCtrl: ModalController) {
  }

  async ngOnInit() {
    await this.defaultUtil.timeout(300);
    this.validSizeModal();
  }

  validSizeModal() {
    const modalHeight = this.modalOptionsBody?.nativeElement?.getBoundingClientRect()?.height || 0;
    const optionHeight = this.optionElement?.nativeElement?.getBoundingClientRect()?.height || 0;
    const modalRef: any = this.defaultUtil.getFirstElement(document.getElementsByClassName('default-options-modal'));
    const extraMargin = this.props?.listButtons?.length > 4 ? 40 : 0;
    const calcModalSize: number = (modalHeight  + (extraMargin + optionHeight * this.props?.listButtons?.length) );
    modalRef.style.setProperty('--height', `${calcModalSize}px`);
    modalRef.style.transition = `all 0.2s`;
  }

  close(data: string, disable: boolean) {
    if(disable) {
      this.modalCtrl.dismiss(null);
      return;
    }
    this.modalCtrl.dismiss(data);
    return;
  }

}
