<div class="comment__container">
  <app-profile-photo fontSize="20px"
                     style="cursor: pointer"
                     class="comment__container--margin-photo"
                     (click)="clickProfilePhoto()"
                     [borderLightGray]="true"
                     [size]="{width: '35px', height: '35px'}"
                     [photoUrl]="comment.user.photo"
                     [userName]="comment.user.name"></app-profile-photo>
  <div class="comment__comment">
    <div class="comment__comment--top">
      <div class="comment__comment--top__name">{{comment.user.name}}</div>
      <div class="comment__comment--top__container-time">
        <div class="comment__comment--top__container-time--time">{{time}}</div>
        <ion-icon *ngIf=" listButtonFilter.length" (click)="modalOptions()" class="comment__comment--top__container-time--icon-options" name="ellipsis-horizontal"></ion-icon>
      </div>
    </div>
    <div class="comment__comment--text">
      {{textOptions?.text}}
      <span class="comment__comment--text__more" *ngIf="textOptions?.shortText && textDesc === 'more'" (click)="setTextMode()">{{textDesc}}
        ...</span>
      <span class="comment__comment--text__more" *ngIf="textOptions?.largeText && textDesc === 'less'"
            (click)="setTextMode()">...{{textDesc}}</span>
    </div>
  </div>
</div>
