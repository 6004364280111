import {Injectable} from '@angular/core';
import {HttpClient, HttpEventType} from '@angular/common/http';
import {EmitEventService} from './emit-event.service';


@Injectable({
  providedIn: 'root'
})
export class AddPipeService {

  public addpipeUrlValues: string;

  constructor(
    private http: HttpClient,
    private emitEventService: EmitEventService,
  ) {
    this.addpipeUrlValues = 'https://cdn.addpipe.com/2.0/precheck.php';
  }

  getAddpipeValues(environmentId: string, accountHash: string): any {
    return new Promise(resolve => {
      this.http.get(`${this.addpipeUrlValues}?accountHash=${accountHash}&environmentId=${environmentId}`)
        .subscribe((data) => resolve(data));
    });
  }

  postVideoAddPipe(url: string,
                   environmentId: string,
                   accountHash: string,
                   file: File,
                   uploadType: string,
                   fileName = null): any {
    return new Promise(resolve => {
      try {
        const formData = new FormData();
        formData.append(uploadType, uploadType);
        if (fileName) {
          formData.append('FileInput', file, fileName);
        } else {
          formData.append('FileInput', file);
        }
        formData.append('accountHash', accountHash);
        formData.append('payload', '');
        formData.append('httpReferer', encodeURIComponent(window.location.href));
        formData.append('mrt', '180');
        formData.append('environmentId', environmentId);
        formData.append('audioOnly', '0');
        this.http.post(`https://${url}/upload`, formData, {
          reportProgress: true,
          observe: 'events'
        }).subscribe((resp) => {
          if (resp.type === HttpEventType.Response) {
            resolve(resp?.body);
          }
          if (resp.type === HttpEventType.UploadProgress) {
            const percentDone = Math.round(100 * resp.loaded / resp.total);
            this.emitEventService.publishDataUploadProgress(percentDone);
          }
        });
      } catch (e) {
        console.log('e >>>>>>>> postVideoAddPipe', e);
      }
    });
  };

};
