import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DefaultUtil} from '../../utils/default.util';

@Component({
  selector: 'app-time-video',
  templateUrl: './time-video.component.html',
  styleUrls: ['./time-video.component.scss'],
})
export class TimeVideoComponent implements OnInit {

  @Input()
  duration: number;

  @Input()
  size: number;

  constructor(private defaultUtil: DefaultUtil) { }

  ngOnInit() {}

  validTimeVideo(time) {
    if(!time){return `Re-upload Required`;}
    return this.defaultUtil.formateTimeMinutes(time);
  }

}
