import {Injectable} from '@angular/core';
import {Router, RoutesRecognized} from '@angular/router';
import {filter, pairwise} from 'rxjs/operators';
import {EmitEventService} from './emit-event.service';

@Injectable({
  providedIn: 'root'
})
export class PreviousRouteService {

  private previousUrl: string;
  private currentUrl: string;
  private all = [];

  constructor(private router: Router,
              private emitService: EmitEventService) {
    this.currentUrl = this.router.url;
    this.previousUrl = null;
  }

   getPreviousUrlEvent() {
    this.router.events.pipe(filter((event: any) => event instanceof RoutesRecognized), pairwise())
      .subscribe((events: RoutesRecognized[]) => {
        this.emitService.pusblishChangePage(true);
        this.previousUrl = events[0].urlAfterRedirects;
        this.currentUrl = events[1].urlAfterRedirects;
        this.all = events;
      });
  }

   getPreviousUrl() {
    return this.previousUrl;
  }

   getCurrentUrl() {
    return this.currentUrl;
  }

  getAll() {
    return this.all;
  }
};
