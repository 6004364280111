import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-star-review',
  templateUrl: './star-review.component.html',
  styleUrls: ['./star-review.component.scss'],
})
export class StarReviewComponent implements OnInit {
  @Input()
  public avaliation: number;

  @Input()
  public textAvaliation: string;

  @Input()
  public mode: 'STARS' | 'TEXT' = 'STARS';

  quantity: any;

  constructor() {
    this.quantity = new Array(5);
  }

  ngOnInit() {
  }

}
