import {Injectable} from '@angular/core';
import {AlertController, LoadingController, ModalController} from '@ionic/angular';
import {environment} from 'src/environments/environment';
import {PathStorage} from '../enums/path-storage';
import {CameraPage} from '../pages/camera/camera.page';
import {AddPipeService} from '../services/add-pipe.service';
import {AskService} from '../services/ask.service';
import {MediaService} from '../services/media.service';
import {DefaultUtil} from '../utils/default.util';
import {ModalContestPage} from '../pages/modal-contest/modal-contest.page';
import {TrialService} from '../services/trial.service';
import {FeedService} from '../services/feed.service';
import {AnalyticsUtil} from '../utils/analytics.util';
import {AnalyticsEvents} from '../enums/events';
import {ColorToast} from '../enums/color-toast';
import {PlatformUtil} from '../utils/platform.util';
import {MediaUtil} from '../utils/media.util';
import {InAppBrowser, InAppBrowserObject} from '@awesome-cordova-plugins/in-app-browser/ngx';

@Injectable({
  providedIn: 'root',
})
export class AskHelper {
  constructor(
    private askService: AskService,
    private modalCtrl: ModalController,
    private addpipeService: AddPipeService,
    private mediaService: MediaService,
    private alertController: AlertController,
    private trialService: TrialService,
    private defaultUtils: DefaultUtil,
    private feedService: FeedService,
    private analyticsUtil: AnalyticsUtil,
    private platformUtil: PlatformUtil,
    private mediaUtil: MediaUtil,
    private loadingCtrl: LoadingController,
    private iab: InAppBrowser,
  ) {
  }

  async executeAction({
                        action,
                        data,
                        chatId,
                        callBackShare = null,
                        callBackRating = null,
                        callBackLoad = null,
                        callBackReviewModal = null,
                        callBackScrollToBottom = null
                      }) {
    this.executeAnalytics(action.status, data);
    switch (action.status) {
      case 'dareverifyrejected':
        return {};
      case 'confirmed':
        await this.changeStatus(data, action.status, chatId);
        callBackScrollToBottom();
        return true;
      case 'trial':
        await this.saveTrial(data, chatId);
        await this.changeStatus(data, action.status, chatId);
        callBackScrollToBottom();
        return true;
      case 'accepted':
      case 'chicken':
      case 'ignore':
      case 'contestedcancel':
        await this.changeStatus(data, action.status, chatId);
        callBackScrollToBottom();
        return true;
      case 'sent':
        const result = await this.changeStatus(data, action.status, chatId);
        return result;
      case 'record':
        if (this.platformUtil.isDesktop()) {
          const cameraExists = await this.defaultUtils.cameraExist();
          if (!cameraExists) {
            this.defaultUtils.globalToast(
              'you don\'t have a camera to record a video!',
              ColorToast.primary,
              6000
            );
            return;
          }
        }
        try {
          if (this.platformUtil.isSafariDesktop()) {
            this.defaultUtils.globalToast(
              'Please, switch to Chrome, Firefox to record directly in the browser or access by your phone!',
              ColorToast.primary,
              8000
            );
            return;
          }
          if (callBackLoad) {
            callBackLoad(false);
          }
          const record: any = await this.initCamera(data);

          if (!record?.data) {
            return;
          }
          if (callBackLoad) {
            callBackLoad(true);
          }
          const loading = await this.loadingCtrl.create({message: 'Uploading'});
          loading.present();
          const {url, urlThumb} = await this.uploadVideo(record.data);
          loading.dismiss();
          data.dare.dareMedia = {
            imageUrl: urlThumb
              ? urlThumb
              : url.toString().replace('.mp4', '.jpg'),
            videoUrl: url,
            timezone: new Date().getTimezoneOffset(),
            mediaType: 'video',
            duration: await this.defaultUtils.getDurationVideo(record.data?.video)
          };
          action.status = 'confirm';
          this.executeAnalytics(action.status, data);
          await this.changeStatus(data, action.status, chatId);
          callBackScrollToBottom();
          return true;
        } catch (e) {
          return false;
        }
      case 'contest':
        const contestMessage = await this.modalPromptComponent(
          data?.sendTo?.name
        );
        if (!contestMessage) {
          return null;
        }
        data.dare.contestReason = contestMessage;

        await this.changeStatus(data, 'contested', chatId);
        callBackScrollToBottom();
        return;
      case 'contested':
        return {};
      case 'accept_expired':
        await this.changeStatus(data, action.status, chatId);
        callBackScrollToBottom();
        return true;
      case 'trialconfirmwon':
        return {};
      case 'trialconfirmlost':
        return {};
      case 'cancelled':
        return {};
      case 'cancelledbysender':
        return {};
      case 'contestedcancel':
        return {};
      case 'autoconfirmed':
        await this.changeStatus(data, action.status, chatId);
        callBackScrollToBottom();
        return true;
      case 'taken_time_expired':
        await this.changeStatus(data, action.status, chatId);
        callBackScrollToBottom();
        return true;
      case 'share':
        if (callBackShare) {
          await callBackShare();
        }
        return true;
      case 'rating':
        if (callBackRating) {
          await callBackRating();
        }
        return true;
      case 'review':
        if (callBackReviewModal) {
          await callBackReviewModal();
        }
        return true;
      default:
        return status;
    }
  }

  executeAnalytics(status, data) {
    switch (status) {
      case 'record':
        if (data?.sendBy?.id === 'admin') {
          this.analyticsUtil.registerEvent(AnalyticsEvents.openCamToAdminAsk, this.analyticsContentAsk(data));
        } else {
          this.analyticsUtil.registerEvent(AnalyticsEvents.openCamToAsk, this.analyticsContentAsk(data));
        }
        break;
      case 'accepted':
        if (data?.sendBy?.id === 'admin') {
          this.analyticsUtil.registerEvent(AnalyticsEvents.acceptedAdminAsk, this.analyticsContentAsk(data));
        } else {
          this.analyticsUtil.registerEvent(AnalyticsEvents.acceptedAsk, this.analyticsContentAsk(data));
        }
        break;
      case 'confirm':
        if (data?.sendBy?.id === 'admin') {
          this.analyticsUtil.registerEvent(AnalyticsEvents.confirmAdminAsk, this.analyticsContentAsk(data));
        } else {
          this.analyticsUtil.registerEvent(AnalyticsEvents.confirmAsk, this.analyticsContentAsk(data));
        }
        break;
      case 'autoconfirmed':
      case 'confirmed':
        this.analyticsUtil.registerEvent(
          AnalyticsEvents.confirmedAsk,
          this.analyticsContentAsk(data)
        );
        break;
      case 'trial':
        this.analyticsUtil.registerEvent(
          AnalyticsEvents.trialAsk,
          this.analyticsContentAsk(data)
        );
        break;
      case 'taken_time_expired':
      case 'accept_expired':
        if (data?.sendBy?.id === 'admin') {
          this.analyticsUtil.registerEvent(AnalyticsEvents.expiredAdminAsk, this.analyticsContentAsk(data));
        } else {
          this.analyticsUtil.registerEvent(AnalyticsEvents.expiredAsk, this.analyticsContentAsk(data));
        }
        break;
      case 'chicken':
        if (data?.sendBy?.id === 'admin') {
          this.analyticsUtil.registerEvent(AnalyticsEvents.rejectedAdminAsk, this.analyticsContentAsk(data));
        } else {
          this.analyticsUtil.registerEvent(AnalyticsEvents.rejectedAsk, this.analyticsContentAsk(data));
        }
        break;
      case 'contestedcancel':
      case 'ignore':
      case 'cancelled':
      case 'cancelledbysender':
        if (data?.sendBy?.id === 'admin') {
          this.analyticsUtil.registerEvent(AnalyticsEvents.cancelAskAdminAsk, this.analyticsContentAsk(data));
        } else {
          this.analyticsUtil.registerEvent(AnalyticsEvents.cancelAsk, this.analyticsContentAsk(data));
        }
        break;
    }
  }

  analyticsContentAsk(ask) {
    return {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      content_type: 'ask',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      content_id: ask.messageId,
      currency: 'USD',
      value: ask.dare.amount,
    };
  }

  async changeStatus(data, newStatus, chatId) {
    let result;

    try {
      delete data?.linkOptions;
      this.defaultUtils.setUpdateMyProfile();
      // const time = firebase.default.firestore.FieldValue.serverTimestamp();
      result = await this.askService.update(
        {
          ...data,
          updatedAt: this.defaultUtils.getDefaultDateFirebase(),
          dare: {...data.dare, status: newStatus},
          readed: false
        },
        chatId,
        data.id,
        data.sendTo.id
      );
    } catch (error) {
      console.log(error);
      throw new Error(error);
    }

    return data;
  }

  async initCamera(data) {
    return new Promise(async (resolve) => {
      if (this.platformUtil.isCordova()) {
        const loading = await this.loadingCtrl.create({message: 'Loading...'});
        setTimeout(() => {
          loading.present();
        }, 1000);
        const video = await this.mediaUtil.getNativeCameraVideo({
          limit: 1,
          duration: 180,
          quality: 80,
        });
        loading.dismiss();
        resolve({data:video});
      } else if (this.defaultUtils.validCamera()) {
        const modal = await this.modalCtrl.create({
          component: CameraPage,
          swipeToClose: true,
          backdropDismiss: false,
          componentProps: {
            ask: data?.dare?.name,
          },
        });
        await modal.present();
        const value = await modal.onDidDismiss();
        resolve(value);
      } else {
        const alert = await this.alertController.create({
          message: 'Record Video Response<br><br>(3 minutes max)',
          buttons: [
            {
              text: 'OK',
              handler: async () => {
                const nativeFile = await this.mediaUtil.cameraVideo();
                resolve({data: {video: nativeFile, image: null}});
              },
            },
          ],
        });
        await alert.present();
      }
    });
  }

  async uploadVideo(data) {
    if (data) {
      const {video, image} = data;
      const {html5Server} = await this.addpipeService.getAddpipeValues(
        environment.addpipe.eid,
        environment.addpipe.accountHash
      );
      const uploadType = 'deskUp';
      let fileName = null;
      let file = new File([video], `${new Date().valueOf().toString()}.MP4`);
      if (this.platformUtil.isCordova()) {
        file = video;
        fileName = `${new Date().valueOf().toString()}.MP4`;
      }
      const upload = await this.addpipeService.postVideoAddPipe(
        html5Server,
        environment.addpipe.eid,
        environment.addpipe.accountHash,
        file,
        uploadType,
        fileName,
      );
      const url = `https://storage.googleapis.com/${environment.firebaseConfig.storageBucket}/${PathStorage.dareProofVideos}/${upload.f}`;
      let urlThumb = null;
      if (image) {
        const [name, type] = upload.f.split('.');
        const pathImage = `${PathStorage.dareProofVideos}/${name}_thumb.jpg`;
        urlThumb = await this.mediaService.uploadFileFirebase(pathImage, image);
      }
      return {url, urlThumb};
    }
  }

  async presentAlertContest() {
    const alert = await this.alertController.create({
      header: 'Contest!',
      inputs: [
        {
          name: 'message',
          id: 'message',
          type: 'textarea',
          placeholder: 'Type your message...',
        },
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
        },
        {
          text: 'Ok',
        },
      ],
    });

    await alert.present();

    const dismiss = await alert.onDidDismiss();

    return dismiss.data?.values.message || null;
  }

  async modalPromptComponent(name) {
    try {
      return new Promise(async (resolve) => {
        const modal = await this.modalCtrl.create({
          component: ModalContestPage,
          swipeToClose: true,
          backdropDismiss: true,
          showBackdrop: true,
          mode: 'md',
          cssClass: 'contest-modal',
          componentProps: {
            titleModal: `Explain ${name} why`,
            titleButton: 'Contest',
            titleInput: 'Your Reason'
          },
        });
        modal
          .onDidDismiss()
          .then((response) => resolve(response?.data?.reason));
        await modal.present();
      });
    } catch (e) {
    }
  }

  async saveTrial(data, chatId) {
    const object = {
      chatId,
      messageId: data.id,
      createdAt: new Date(),
      dare: data.dare,
      sendBy: data.sendBy,
      sendTo: data.sendTo,
      timestamp: new Date().valueOf(),
      updatedAt: new Date(),
    };

    await this.trialService.create(object);
  }

  initInAppBrowser(url) {
    const browser: InAppBrowserObject = this.iab.create(
      url,
      '_blank',
      {location: 'no'}
    );
    browser.on('loadstart').subscribe(event => {
      if (event?.url.match('localhost') || event?.url.match('jointruly')) {
        browser.close();
        console.log('updateeeeeeee');
      }
    });
  }


  async executeActionRequest({
                               action,
                               data,
                               chatId
                             }) {
    switch (action.status) {
      case 'join':
        if (this.platformUtil.isCordova()) {
          this.initInAppBrowser(data?.videoCall?.joinUrl);
        } else {
          window.open(data?.videoCall?.joinUrl);
        }
        return false;
      case 'accepted':
      case 'rejected':
        return await this.changeStatusRequestSession(data, action.status, chatId);
      default:
        return false;
    }
  }

  async changeStatusRequestSession(data, newStatus, chatId) {
    let result;

    try {
      delete data?.linkOptions;
      this.defaultUtils.setUpdateMyProfile();
      result = await this.askService.updateSession(
        {
          ...data,
          updatedAt: this.defaultUtils.getDefaultDateFirebase(),
          videoCall: {status: newStatus},
          readed: false
        },
        chatId,
        data.id,
        data.sendTo.id
      );
    } catch (error) {
      console.log(error);
      throw new Error(error);
    }

    return data;
  }
}
