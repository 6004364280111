<ion-content>
  <div class="container">
    <ion-icon class="icon" (click)="close()" name="close-outline"></ion-icon>
    <div class="wrapper">
      <div class="container-title">
        <div class="title">{{ titleModal }}</div>
      </div>
      <div style="margin-top:20px"></div>
      <div class="input-wrapper textarea">
        <textarea  style="margin: 34px 18px 19px 18px !important;" [(ngModel)]="reason" class="secundary-color" placeholder="placeholder" autocomplete="off"></textarea>
        <label class="label secundary-color postion-prompt">{{ titleInput }}</label>
      </div>
      <div class="ctr-button">
        <ion-button class="button" (click)="confirm()">
          <ion-label>{{titleButton}}</ion-label>
        </ion-button>
      </div>
    </div>
  </div>
</ion-content>
