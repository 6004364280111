export enum ColorToast {
  primary =  'primary',
  secondary = 'secondary',
  tertiary = 'tertiary',
  success = 'success',
  danger = 'danger',
  light = 'light',
  dark = 'dark',
}


