import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import firebase from 'firebase/app';
import 'firebase/auth';
import {User} from '../models/user';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  collectionName = 'users';

  currentUser = {} as User;

  constructor(private firestore: AngularFirestore, private http: HttpClient) {
  }

  create(data: User) {
    return this.firestore.collection(this.collectionName).add(data);
  }

  getById(id: string): any {
    return this.firestore.collection(this.collectionName).doc(id).ref.get();
  }

  update(id: string, data: any) {
    return this.firestore.collection(this.collectionName).doc(id).update(data);
  }

  getByTags(tags) {
    return this.firestore
      .collection(this.collectionName)
      .ref.where('tags', 'in', tags)
      .get();
  }

  getUserByUsername(username) {
    return this.firestore
      .collection(this.collectionName)
      .ref
      .where('name', '==', username)
      .limit(1)
      .get();
  }

  searchUsers(user) {
    return this.firestore
      .collection(this.collectionName)
      .ref.orderBy('name')
      .where('name', '>=', user.toLowerCase())
      .where('name', '<=', user.toLowerCase() + '\uf8ff')
      .limit(30)
      .get();
  }

  searchUsersByFullName(name) {
    return this.firestore
      .collection(this.collectionName)
      .ref.orderBy('fullName')
      .where('fullName', '>=', name.toLowerCase())
      .where('fullName', '<=', name.toLowerCase() + '\uf8ff')
      .limit(30)
      .get();
  }

  resetUser() {
    this.currentUser = {} as User;
    localStorage.clear();
  }

  getAll() {
    //return this.firestore.collection(this.collectionName).ref.orderBy('tagList', 'desc').limit(100).get();
    return this.firestore
      .collection(this.collectionName)
      .ref
      .get();
  }

  getUsersByUsername(name) {
    return this.firestore
      .collection(this.collectionName)
      .ref.orderBy('name')
      .where('name', '>', name?.toLowerCase())
      .limit(32)
      .get();
  }

  getUsers() {
    return (
      this.firestore
        .collection(this.collectionName)
        .ref.orderBy('tags', 'desc')
        //.orderBy('name')
        .limit(40)
        .get()
    );
  }

  async deleteAuthUser() {
    await firebase.auth().currentUser.delete();
  }

  async getCurrentUser() {
    return new Promise((resolve) => {
      firebase.auth().onAuthStateChanged(async (user) => {
        if (user) {
          resolve(
            this.getUserByUsername(user?.displayName.toLowerCase() || '')
          );
        } else {
          resolve(null);
        }
      });
    });
  }

  getUserByEmailorUsername(email: string): any {
    return new Promise((resolve) => {
      this.http
        .post(`${environment.cloudFunctionUrl}/user/get/byEmailorName`, {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          email,
        })
        .subscribe((data) => resolve(data));
    });
  }

  verifyEmail(email): any {
    return new Promise((resolve) => {
      this.http
        .post(`${environment.cloudFunctionUrl}/user/validEmail`, {
          email,
        })
        .subscribe((value) => resolve(value));
    });
  }

  verifyEmailCode(email, code): any {
    return new Promise((resolve) => {
      this.http
        .post(`${environment.cloudFunctionUrl}/pincode/verify`, {
          email,
          code,
        })
        .subscribe((value) => resolve(value));
    });
  }

  sendEmailVerifyCode(email: any): any {
    return new Promise((resolve) => {
      this.http
        .post(`${environment.cloudFunctionUrl}/pincode/send`, {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          email,
        })
        .subscribe((value) => resolve(value));
    });
  }

  async createUserAuth(email, password) {
    return new Promise((resolve) => {
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then((result) => resolve({success: true, message: ''}))
        .catch((error) => resolve({success: false, message: error.message}));
    });
  }

  updateProfileAuth(displayName, photoURL) {
    return new Promise((resolve) => {
      firebase
        .auth()
        .currentUser.updateProfile({
        displayName,
        photoURL,
      }).then(() => {
          resolve(true);
        }).catch((error) => {
          resolve(false);
        });
    });
  }

  createUserColection(user, uid, photoURL) {
    return new Promise(async (resolve) => {
      const userData = {
        name: user?.name?.toLowerCase() || user?.displayName?.toLowerCase(),
        email: user?.emailPhone?.toLowerCase() || '',
        id: uid,
        phoneNumber: user?.phoneNumber || '',
        photoURL,
        status: 0,
        isVerified: 0,
        isEnabled: 1,
        tags: [],
        createdAt: new Date(),
        updatedAt: new Date(),
      };
      await this.firestore
        .collection(this.collectionName)
        .doc(uid)
        .set(userData);
      resolve(true);
    });
  }

  verifyBlockedAccount(userId): Observable<any> {
    return Observable.create((observer) => {
      this.firestore
        .collection(this.collectionName)
        .doc(userId)
        .ref.onSnapshot((value: any) => {
        const data = value.data();
        observer.next(data);
      });
    });
  }

  subscribeUserInfo(userId): Observable<any> {
    return Observable.create((observer) => {
      try {
        this.firestore
          .collection(this.collectionName)
          .doc(userId)
          .ref.onSnapshot((value: any) => {
          const result = {
            id: value.id,
            ...value.data(),
          };

          observer.next(result);
        });
      } catch (e) {
        console.log(e);
        // observer.next(null);
      }
    });
  }

  sendEmail(data: any): any {
    return new Promise((resolve) => {
      this.http
        .post(`${environment.cloudFunctionUrl}/email/send`, {data})
        .subscribe((value) => resolve(value));
    });
  }

  getTopUsersLogin(): any {
    return new Promise((resolve) => {
      this.http
        .get(`${environment.cloudFunctionUrl}/user/get/topLoginUsers`)
        .subscribe((value) => resolve(value));
    });
  }

  getUserByTags(id) {
    return this.firestore
      .collection(this.collectionName)
      .ref.where('tags', 'array-contains', id)
      .get();
  }

  getUserByAllTags(ids) {
    return this.firestore
      .collection(this.collectionName)
      .ref
      .where('tags', 'array-contains-any', ids)
      .get();
  }

  getUsersLimit(limit) {
    return this.firestore
      .collection(this.collectionName)
      .ref.limit(limit)
      .orderBy('updatedAt', 'desc')
      .get();
  }

  getUsersByDate(limit) {
    return this.firestore
      .collection(this.collectionName)
      .ref.limit(limit)
      .orderBy('createdAt', 'desc')
      .get();
  }


  getRecentUsers(limit) {
    return this.firestore
      .collection(this.collectionName)
      .ref.limit(limit)
      .where('isVerified', '==', 1)
      .orderBy('updatedAt', 'desc')
      .get();
  }

  delete(id) {
    return this.firestore.collection(this.collectionName).doc(id).delete();
  }

  resetPassword({apiKey, oobCode, newPassword}): any {
    return new Promise((resolve, reject) => {
      this.http
        .post(`${environment.firebaseIdentity}/accounts:resetPassword?key=${apiKey}`, {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          oobCode,
          newPassword
        })
        .subscribe(
          (value) => resolve(value),
          (value) => reject(value?.error)
        );
    });
  }

  deleteUser(data: any): any {
    return new Promise((resolve,reject) => {
      this.http
        .post(`${environment.cloudFunctionUrl}/user/delete`, {...data})
        .subscribe((value) => resolve(value),
          (value) => reject(value?.error)
        );
    });
  }
  getIpData(): any {
    return new Promise((resolve,reject) => {
      this.http
        .get(`https://ipapi.co/json`)
        .subscribe((value) => resolve(value),
          (value) => reject(value)
        )
    });
  }

  async createUserAuthExternal(data) {
    return new Promise((resolve,reject) => {
      this.http
        .post(`${environment.cloudFunctionUrl}/user/create/auth`, {...data})
        .subscribe((value) => resolve(value),
          (value) => reject(value?.error)
        );
    });
  }

  async createLoginEmailLink(data) {
    return new Promise((resolve,reject) => {
      this.http
        .post(`${environment.cloudFunctionUrl}/user/create/linkEmail`, {...data})
        .subscribe((value) => resolve(value),
          (value) => reject(value?.error)
        );
    });
  }

  updateUserEmail(data: any) {
    return new Promise((resolve,reject) => {
      this.http
        .post(`${environment.cloudFunctionUrl}/user/update/email`, {...data})
        .subscribe((value) => resolve(value),
          (value) => reject(value?.error)
        );
    });
  }
}
