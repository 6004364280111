<!-- <div class="item-menu__section" *ngIf="categories?.length">
  <div class="item-menu__section--item" (click)="goToCategory(t,  getBackgroundColor(bIndex),getBackDecoration(bIndex) )" *ngFor="let t of categories; let bIndex = index">
    <div class="item-menu__section--item-ctn-around" [ngStyle]="{'background-color':getBackDecoration(bIndex)}">
      <div class="item-menu__section--item__ctn-icon" [ngStyle]="{'background-color': getBackgroundColor(bIndex)}">
        <ion-icon class="item-menu__section--item__ctn-icon--icon" [ngStyle]="{'color': getIconColor(bIndex)}"
                  [name]="t?.category?.icon || 'information-circle'"></ion-icon>
      </div>
    </div>
    <div class="item-menu__section--item__item-text">{{t?.category?.name || t?.tag}}</div>
  </div>
</div> -->


<ion-grid *ngIf="categories?.length" class="category-grid">
  <ion-row class="category-row">
    <ion-col size="auto" *ngFor="let t of categories; let bIndex = index">
      <ion-button (click)="goToCategory(t, getBackgroundColor(bIndex), getBackDecoration(bIndex))"
                  [ngStyle]="{'background-color': getBackDecoration(bIndex)}">
        <!-- //NOTE:code below is to format category icons -->
        <!-- <ion-icon slot="start" [ngStyle]="{'color': getIconColor(bIndex)}"
                  [name]="t?.category?.icon || 'information-circle'"></ion-icon> -->
        {{t?.category?.name || t?.tag}}
      </ion-button>
    </ion-col>
  </ion-row>
</ion-grid>
