import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  collectionName = 'notifications';

  constructor(private firestore: AngularFirestore, private http: HttpClient) { }


  addNotification(data) {
    return this.firestore.collection(this.collectionName).ref.add(data);
  }

  updateNotification(id, data) {
    return this.firestore
      .collection(this.collectionName)
      .ref.doc(id)
      .update(data);
  }

  subscribeNotification(userid): Observable<any> {
    return Observable.create((observer) => {
      this.firestore
        .collection(this.collectionName)
        .ref.where('sendTo.id', '==', userid)
        .where('readed', '==', false).limit(50)
        .onSnapshot(async (value: any) => {

          if (value.size > 0) {
            observer.next(
              value.docs.map((x) => ({
                  id: x.id,
                  newId: x.id,
                  ...x.data(),
                }))
            );
          }

          observer.next(null);
        });
    });
  }

  createOnesignalNotification(data) {
    return this.http.post(`${environment.cloudFunctionUrl}/push/send`, {
      ...data
    }).toPromise();
  }

  createNotificationAllDevices(data) {
    return this.http.post(`${environment.cloudFunctionUrl}/push/sendAll`, {
      ...data
    }).toPromise();
  }
}
