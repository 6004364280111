import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AskService {
  collectionName = 'wallets';

  constructor(private firestore: AngularFirestore, private http: HttpClient) { }

  getWalletByUser(userId) {
    return this.firestore.collection(this.collectionName).doc(userId).ref.get();
  }

  send(data) {
    return this.http
      .post(`${environment.cloudFunctionUrl}/dare/send`, {
        data,
      })
      .toPromise();
  }

  sendSession(data) {
    return this.http
      .post(`${environment.cloudFunctionUrl}/dare/sendSession`, {
        data,
      })
      .toPromise();
  }

  update(data, chatId, askId, sendToId) {
    return this.http.post(`${environment.cloudFunctionUrl}/dare/update`, {
      chatId,
      dareId: askId,
      sendToId,
      data,
    }).toPromise();
  }

  updateSession(data, chatId, askId, sendToId) {
    return this.http.post(`${environment.cloudFunctionUrl}/dare/updateSession`, {
      chatId,
      dareId: askId,
      sendToId,
      data,
    }).toPromise();
  }

  async updateUnreadMessages(ids = [], chatId, sendToId) {
    const saveArray = [];
    for (const id of ids) {
      saveArray.push(
        this.update({ readed: true }, chatId, id, sendToId)
      );
    }
    await Promise.all(saveArray);
  }
}
