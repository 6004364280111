<ion-content>
  <div class="main-wrapper">
    <div class="container">
      <ion-icon (click)="close()"  class="icon" name="close-outline"></ion-icon>
      <div class="wrapper">
        <div class="title">
          Make Your Profile Stand Out
        </div>
        <div class="subTitle">
          Help coaches and coachees get to know you better by sharing your skills, experience, and goals.
        </div>
        <div class="ctr-button">
          <ion-button (click)="start()" class="button secundary-color">
            <ion-label style="font-size: 22px;padding: 0px 24px;">
              Edit My Profile
            </ion-label>
          </ion-button>
        </div>
        <div (click)="close()" class="skip">Skip for Now</div>
      </div>
    </div>
  </div>
</ion-content>
