<ion-content
  [scrollEvents]="true"
  #contentMyProfile
  id=""
  (ionScroll)="onScroll($event)"
>
<!-- [backButtonMobile]="isMobile" -->
  <app-home-view
    pageName="Profile"
    [simpleHeader]="true"
    [hideHeader]="isMobile"
    [hasCateg]="false">
    <div style="display: flex">
      <div class="my-profile__wrapper">
        <app-profile-info (onClickEditProfile)="goToEditProfile(false)"
                          (onClickShare)="shareProfile()"
                          (onclickEditBackgroud)="editBackground()"
                          (onClickVerify)="verifyAccount()"
                          (onClickPostVideo)="getCamera()"
                          (onClickAvaliations)="goToReviewList()"
                          [isProfileOwner]="true"
                          [enableEdit]="true"
                          [enableShare]="true"
                          [adminList]="true"
                          *ngIf="user?.id"
                          [user]="user"></app-profile-info>
        <div>
          <app-profile-tags *ngIf="user?.tags?.length || isLoading" [isLoading]="isLoading" [grayTag]="true"
                            (onClickTag)="modalGeneralSearch($event)"
                            [chunkedtags]="chunkedtags"></app-profile-tags>
        </div>
        <div style="margin-bottom: 30px"></div>
        <div class="container-post">
          <app-post [feed]="f" [index]="i+'myprofile'" [isMyProfile]="true" [optionReport]="false"
                    [optionEdit]="f?.owner"
                    [showPinnedLabel]="true"
                    [loggedUser]="user" [removeFirstBorder]="i === 0" *ngFor="let f of  this.feed; let i = index">
            <app-video [isLoggedUser]="true"
                       [index]="i+'myprofile'"
                       (onReupload)="reuploadVideo($event)"
                       [allowedReupload]="f?.user?.id === user?.id && f?.media.type === 'shortVideo'"
                       [feed]="f"></app-video>
          </app-post>
        </div>
        <app-load-video-profile *ngIf="isLoading"></app-load-video-profile>
        <app-empty-message-content
          icon="camera-outline"
          linkText=""
          (onClickLink)="getCamera()"
          style="width: 100%; display:flex;flex-direction: column;align-items: center;align-content: space-between;"
          *ngIf="feed?.length === 0"
        >
        </app-empty-message-content>
        <ion-infinite-scroll *ngIf="this.pagination.enable" threshold="10px" (ionInfinite)="ionInfinite($event)"
                             style="margin-top: 72px">
          <ion-infinite-scroll-content class="loading-spinner" loadingSpinner="lines">
          </ion-infinite-scroll-content>
        </ion-infinite-scroll>
        <div style="margin-bottom: 300px"></div>
      </div>
    </div>
  </app-home-view>
</ion-content>
<app-fab-to-top [showFab]="showFab" (onClickFab)="scrollToTop()"></app-fab-to-top>

