<div style="overflow: hidden;" [ngClass]="{'sended-message-container': sendBy, 'received-message-container':  !sendBy}">
  <div class="font-ask hasButtons"
       (click)="openModalPhotoViewer(message?.media?.imageUrl )"
       *ngIf="message?.type !== 'upload' &&  message?.type !== 'file' && !message?.media?.verifyImage"
       [ngClass]="{  'sended-message-video-preview': sendBy,'received-message-video-preview': !sendBy }"
       [style.background]="'url(' + message?.media?.imageUrl + ')'"
       [style.background-repeat]="'no-repeat'"
       [style.background-size]="'contain'"
       [style.background-position]="'center'">
    <img *ngIf="message?.type === 'video' && !message?.media?.verifyImage" (click)="play(this.message)"
         style="position: relative; top: 33%; cursor: pointer"
         src="assets/icon/play-button.svg" alt="">
    <!--    <ion-spinner *ngIf="this.message?.media?.verifyImage" class="spinner" color="light"></ion-spinner>-->
  </div>
  <div *ngIf="message?.type === 'file'">
    <div class="container-file" (click)="clickFile(message)">
      <ion-icon class="icon-file" name="document"></ion-icon>
      <div class="file-name">{{ message?.media?.name}}</div>
    </div>
  </div>
  <!--  -->
  <div *ngIf="message?.type === 'upload' || message?.media?.verifyImage">
    <div class="preview-upload"></div>
    <ion-progress-bar color="light" [value]="progress.value"></ion-progress-bar>
  </div>
  <ion-item lines="none" class="actions-item separator-ask font-ask" *ngIf="message?.text"
            [ngClass]="{'action-sended':sendBy, 'action-received':!sendBy }">
    <div style="width: 100%;margin-left: 12px;" (click)="onClickLink($event, message)"
         [innerHTML]="message?.linkOptions?.linkText || message?.text"></div>
  </ion-item>
</div>
