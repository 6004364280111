<ion-content scroll="false">
  <app-header-camera [count]="durationText" (close)="close()"></app-header-camera>
  <!--  <app-progress-video-bar [secondsToTransition]="1.7" [size]="82" class="container-progress-bar" [duration]="60"-->
  <!--                          [currentTime]="currentTime"></app-progress-video-bar>-->
  <div *ngIf="isCordova">
    <ion-spinner style="margin-top: 100px;position: absolute;height: 1px;" color="light"></ion-spinner>
  </div>
  <canvas #canvas class="canvas-thumb"></canvas>
  <div class="stopwatch-base" [ngClass]="this.stopWatchAddClass ? 'stopwatch-show' : ''">{{ stopWatchValue }}</div>
  <div class="container-camera">
    <video class="camera" [ngStyle]="{'display': !isStop  ? 'block' :'none'}" #preview playsinline webkit-playsinline
           autoplay muted></video>
    <video class="camera" [style]="dimensionPreview" [ngStyle]="{'display': isStop  ? 'block' :'none'}" #video
           playsinline webkit-playsinline></video>
  </div>
  <div class="ctr-play" *ngIf="stopWatchValue === 0">
    <div class="ctr-interacts">
      <ion-grid>
        <div *ngIf="ask">
          <ion-row>
            <ion-col>
              <ion-row>
                <div class="ctr-text" [ngClass]="{'close': !openText}">
                  <span class="text-ask">{{ ask }}</span>
                </div>
              </ion-row>
            </ion-col>
          </ion-row>
          <!-- <ion-row style="margin-bottom: 10px">
            <ion-col (click)="setOpenText()">
              <ion-row>
                <ion-icon [ngClass]="openText ? 'open-text' : 'close-text'" class="icon-default" name="chevron-down-outline"></ion-icon>
              </ion-row>
            </ion-col>
          </ion-row> -->
        </div>
        <ion-row *ngIf="stepCamera === 3" style="width: 100%">
          <ion-row style="width: 100%">
            <div class="container-scroll">
              <div class="scroll">
                <div class="thumb-choice"
                     *ngFor="let thumb of thumbs"
                     (click)="setThumb(thumb)"
                     [ngClass]="{'thumb-choiced': thumb.valid}"
                     [ngStyle]="{'background-image': 'url('+ thumb.image +')'}">
                </div>
              </div>
            </div>
          </ion-row>
        </ion-row>
        <ion-row *ngIf="stepCamera === 1" class="default-row">
          <ion-col>
          </ion-col>
          <ion-col>
            <div *ngIf="!this.loadCamera" class="container-play" (click)="handleCamera()">
              <div class="play" [ngClass]="{'pause':isPlay}">
              </div>
            </div>
            <ion-spinner *ngIf="this.loadCamera" color="light"></ion-spinner>
          </ion-col>
          <ion-col>
            <div class="container-btn" (click)="switchCamera()" *ngIf="isMobile && !loadCamera && !isPlay">
              <ion-icon class="icon" name="camera-reverse"></ion-icon>
            </div>
          </ion-col>
        </ion-row>
        <ion-row *ngIf="stepCamera === 2" class="default-row">
          <ion-col size="4">
            <ion-button (click)="cancel()" class="button-default outline">
              Re-take
            </ion-button>
          </ion-col>
          <ion-col size="8" (click)="setStep(3)">
            <ion-button class="button-default ">
              Edit Thumbnail
              <!-- Next -->
            </ion-button>
          </ion-col>
        </ion-row>
        <ion-row *ngIf="stepCamera === 3" class="default-row">
          <ion-col (click)="accept()">
            <ion-button class="button-default">
              <!-- <ion-icon slot="end" name="send-sharp"></ion-icon> -->
              <!-- Post -->
              Submit
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </div>
</ion-content>
