import { Injectable, NgZone } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AuthService } from './services/auth.service';
import { UserService } from './services/user.service';
import { StorageService } from './services/storage.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService,
    private userService: UserService,
    private storageService: StorageService,
  ) {}

  async canActivate(): Promise<boolean> {
    const currentUser = JSON.parse(localStorage.getItem('current-user'));

    // If no user exists in local storage, just allow access (no forced redirect)
    if (!currentUser) {
      console.warn('No user found, allowing access to homepage.');
      return true; // 🔥 This prevents an unnecessary redirect to login
    }

    const token = this.storageService.accessToken;

    try {
      await this.authService.validateToken(token);
    } catch (error) {
      console.warn('Token validation failed:', error);

      // Only redirect if user MUST be logged in
      if (error.error?.code === 'auth/id-token-expired') {
        const uid = localStorage.getItem('user-uid');
        await this.refreshToken(uid);
      } else {
        return true; // 🔥 Instead of redirecting to /, just allow the page to load
      }
    }

    const result = await this.userService.getById(currentUser.id);
    const user = {
      id: result.id,
      ...result.data(),
    };

    localStorage.setItem('current-user', JSON.stringify(user));
    this.userService.currentUser = { ...user };

    return true;
  }


  async refreshToken(uid) {
    const resultToken: any = await this.authService.getToken(uid);

    // console.log('resultToken ', resultToken);

    const verifyAuth = await this.authService.signWithCustomToken(
      resultToken.token
    );

    // console.log('verifyAuth ', verifyAuth);

    const idToken = await verifyAuth.user.getIdToken(true);

    localStorage.setItem('access-token', idToken);
  }
}
