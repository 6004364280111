<div class="container">
  <div *ngIf="icon">
    <ion-icon class="icon" *ngIf="iconType === 'ionicIcon'" [name]="icon"></ion-icon>
    <div class="image" *ngIf="iconType === 'image'" [style.background-image]="'url('+icon+')'"></div>
    <svg class="svg" *ngIf="iconType === 'svg'">
      <use [attr.xlink:href]="icon"></use>
    </svg>
    <!-- <div class="shadow"></div> -->
  </div>
  <div class="title">{{ title }}</div>
  <div class="subtitle">{{ subTitle }}</div>
  <div class="link" (click)="clickLink()">{{ linkText }}</div>
  <ion-spinner *ngIf="isLoading" style="margin-top: 20px" color="primary"></ion-spinner>
</div>
