<div class="ctn-header-default"
     [ngStyle]="{'background-color':  backgroundColor || '#06051e',   'height': headerHeight }"
     [ngClass]="{  'remove_fixed': removeFixed,
                  'hide': hideAnimated,
                  'margin-full':marginHeader
                }"
>
  <ion-row class="ctn-row" [ngClass]="{'pos-fixed-safari':isSafariDesktop}">
    <div *ngIf="showContainerLeft" class="container-icon" (click)="customBack ? onCustomBack() : goBack()"
         [ngStyle]="{'color':colorIcon}">
      <ion-icon *ngIf="showIconBack" [ngStyle]="{'color':colorIcon}" class="icon" name="arrow-back-outline"></ion-icon>
      <div *ngIf="user" class="container-user">
        <app-profile-photo fontSize="20px" *ngIf="user?.id" [size]="{width:'32px',height:'32px'}"
                           [isVerified]="user?.isVerified" [photoUrl]="user?.photoURL"
                           [userName]="user?.name"></app-profile-photo>
        <div class="text">{{user?.name}}</div>
        <div class="stamp"></div>
      </div>
    </div>
    <div class="container-image">
      <div class="logo remove-margin"
           [ngStyle]="{width:+sizeLogo.width+'px', height:+sizeLogo.height+'px'  }"
           *ngIf="showIcon" (click)="clickInfo()"></div>
      <div class="title" [ngStyle]="{'color':colorIcon}" *ngIf="showTitle">{{ this.title }}</div>
    </div>
    <div *ngIf="showContainerRight" class="container-icon">
      <ion-icon *ngIf="showIconShare" [ngStyle]="{'color':colorIcon}" class="icon"
                [name]="isIos ? 'share':'share-social'" (click)="share()"></ion-icon>
      <ion-icon *ngIf="showIconOptions" [ngStyle]="{'color':colorIcon}" class="icon" name="ellipsis-horizontal"
                (click)="clickOptions()"></ion-icon>
      <div *ngIf="showIconInfo && showLabel" class="text-click">
        <span class="click" [ngStyle]="{'color':colorIcon}" (click)="navigateToLogin()">Login</span> |
        <span class="click" [ngStyle]="{'color':colorIcon}" (click)="navigateToSignUp()">Sign up</span>
      </div>
      <ion-icon *ngIf="showIconEdit" class="icon" name="create-outline" [ngStyle]="{'color':colorIcon}"
                (click)="clickOptions()"></ion-icon>
    </div>
  </ion-row>
</div>
