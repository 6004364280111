import {Component, Input, OnInit} from '@angular/core';
import {User} from '../../models/user';
import {UserFeed} from '../../models/feed.model';

interface GradientLetters {
  value: string;
  firstColor: string;
  lastColor: string;
}

@Component({
  selector: 'app-profile-photo',
  templateUrl: './profile-photo.component.html',
  styleUrls: ['./profile-photo.component.scss'],
})

export class ProfilePhotoComponent implements OnInit {

  @Input()
  public photoUrl: string;

  @Input()
  public userName: string;

  @Input()
  public isVerified?: boolean;

  @Input()
  public size: {width: string; height: string};

  @Input()
  public fontSize: string;

  @Input()
  public border: boolean;

  @Input()
  public borderBlack: boolean;

  @Input()
  public borderLightGray: boolean;

  @Input()
  public useIonicComponent = true;

  public lettersAndColors: GradientLetters[] = [
    {value:'A', firstColor:'#ff00fa', lastColor:'#ff84fd'},
    {value:'B', firstColor:'#1215da', lastColor:'#ff84fd'},
    {value:'C', firstColor:'#8082d7', lastColor:'#ff84fd'},
    {value:'D', firstColor:'#f600ff', lastColor:'#84b5ff'},
    {value:'E', firstColor:'#ff0000', lastColor:'#ff8984'},
    {value:'F', firstColor:'#0fff00', lastColor:'#6e9975'},
    {value:'G', firstColor:'#00e5ff', lastColor:'#ff84fd'},
    {value:'H', firstColor:'#9c17ca', lastColor:'#b57cc9'},
    {value:'I', firstColor:'#1215da', lastColor:'#8f90dd'},
    {value:'J', firstColor:'#ff9700', lastColor:'#ebcfa7'},
    {value:'K', firstColor:'#0fff00', lastColor:'#6e9975'},
    {value:'L', firstColor:'#00e5ff', lastColor:'#ff84fd'},
    {value:'M', firstColor:'#9c17ca', lastColor:'#b57cc9'},
    {value:'N', firstColor:'#1215da', lastColor:'#8f90dd'},
    {value:'O', firstColor:'#ff00fa', lastColor:'#ff84fd'},
    {value:'P', firstColor:'#1215da', lastColor:'#ff84fd'},
    {value:'Q', firstColor:'#8082d7', lastColor:'#ff84fd'},
    {value:'R', firstColor:'#eb9191', lastColor:'#84b5ff'},
    {value:'S', firstColor:'#ff0000', lastColor:'#ff8984'},
    {value:'T', firstColor:'#0fff00', lastColor:'#6e9975'},
    {value:'U', firstColor:'#00e5ff', lastColor:'#ff84fd'},
    {value:'V', firstColor:'#9c17ca', lastColor:'#b57cc9'},
    {value:'X', firstColor:'#1215da', lastColor:'#ff84fd'},
    {value:'W', firstColor:'#8082d7', lastColor:'#ff84fd'},
    {value:'Y', firstColor:'#eb9191', lastColor:'#84b5ff'},
    {value:'Z', firstColor:'#9c17ca', lastColor:'#b57cc9'},

  ];

  public letter: GradientLetters;

  constructor() { }

  ngOnInit() {
    this.selelectLetter();
  }

  selelectLetter() {
    this.letter = this.lettersAndColors.find(a=>(this.userName?.charAt(0).toUpperCase()  === a?.value));
  }

  validPhoto(photo) {
    if (this.userName.toLowerCase() === 'truly') {
      return 'https://storage.googleapis.com/truly-production-24c15.appspot.com/truly.png';
    }
    return photo;
  }

}
