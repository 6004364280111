import {Injectable} from '@angular/core';
import {HttpClient, HttpEventType, HttpHeaders} from '@angular/common/http';
import {DefaultUtil} from '../utils/default.util';
import {environment} from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CalendlyService {

  constructor(private http: HttpClient) {
  }

  getBasicAccessToken() {
    const credentials = `${environment.calendly.clientId}:${environment.calendly.clientSecret}`;
    return `Basic ${btoa(credentials)}`;
  }

  getBearerToken(token) {
    return `Bearer ${token}`;
  }

  getAccessToken({code}) {
    return new Promise(resolve => {
      const grantType = 'authorization_code';
      const body = new URLSearchParams();
      body.set('grant_type', grantType);
      body.set('redirect_uri', environment.calendly.redirectUrl);
      body.set('code', code);
      const headers = new HttpHeaders({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/x-www-form-urlencoded',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Authorization: this.getBasicAccessToken(),
      });
      this.http.post(`${environment.calendly.auth}/oauth/token`, body.toString(), {
        headers
      }).subscribe(
        data => resolve(data),
        error => resolve(false)
      );
    });
  };

  getRefreshToken(refreshToken) {
    return new Promise(resolve => {
      const grantType = 'refresh_token';
      const body = new URLSearchParams();
      body.set('grant_type', grantType);
      body.set('refresh_token', refreshToken);
      const headers = new HttpHeaders({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/x-www-form-urlencoded',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Authorization: this.getBasicAccessToken(),
      });
      this.http.post(`${environment.calendly.auth}/oauth/token`, body.toString(), {
        headers
      }).subscribe(
        data => resolve(data),
        error => resolve(false)
      );
    });
  };

  getEventTypes({user, organization, token}) {
    return new Promise(resolve => {
      const headers = new HttpHeaders({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Authorization: this.getBearerToken(token),
      });
      this.http.get(`${environment.calendly.url}/event_types?organization=${organization}&user=${user}`, {
        headers
      }).subscribe(
        data => resolve(data),
        error => resolve(false)
      );
    });
  }

  createScheduleShare(scheduleData: any, token: string): any {
    return new Promise((resolve) => {
      const headers = new HttpHeaders({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Authorization: this.getBearerToken(token),
      });
      this.http
        .post(`${environment.calendly.url}/shares`, scheduleData, {
          headers
        })
        .subscribe(
          data => resolve(data),
          error => resolve(false)
        );
    });
  }

  getEventInfo({url, token}) {
    return new Promise(resolve => {
      const headers = new HttpHeaders({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Authorization: this.getBearerToken(token),
      });
      this.http.get(url, {
        headers
      }).subscribe(
        data => resolve(data),
        error => resolve(false)
      );
    });
  }

  getUserAvailability(userUri: string, token: string) {
    return new Promise((resolve) => {
      const headers = new HttpHeaders({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Authorization: this.getBearerToken(token),
      });
      this.http
        .get(`${environment.calendly.url}/user_availability_schedules?user=${userUri}`, {
          headers
        })
        .subscribe(
          data => resolve(data),
          error => resolve(false)
        );
    });
  }
}
