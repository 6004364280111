import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-modal-paid-animation',
  templateUrl: './modal-paid-animation.page.html',
  styleUrls: ['./modal-paid-animation.page.scss'],
})
export class ModalPaidAnimationPage implements OnInit {
  constructor(private modalCtrl: ModalController) {}

  ngOnInit() {}

  close() {
    this.modalCtrl.dismiss(null);
  }
}
