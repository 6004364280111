<div class="ctr-tags" [ngClass]="{'no-margin':isTrending}" [style.margin-left]="marginLeft">
  <div style="display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;"
       class="wrapper-tags">
    <div *ngIf="!isLoading">
      <div class="sub-wrapper" [ngClass]="{'to-right': (aIndex !== 1 && !isTrending)}"
           *ngFor="let item of chunkedtags; let aIndex = index">
        <div class="tag"
             [ngClass]="{
              'mid-size':midSize,
              'selected': (itemTags.selected && enableSelection)
        }" *ngFor="let itemTags of item; let bIndex = index" (click)="select(itemTags, aIndex, bIndex)">
          #{{itemTags.tag}}
        </div>
        <div class="tag no-border"></div>
        <div class="tag no-border"></div>
        <div class="tag no-border"></div>
      </div>
    </div>
    <div *ngIf="isLoading">
      <div class="sub-wrapper" style="width: 100%;" [ngClass]="{'to-right': (a !== 1 && !isTrending)}"
           *ngFor="let item of loadChunkedtags; let a = index">
        <div class="loadTag" *ngFor="let i of item; let b = index">
          <ion-skeleton-text [ngStyle]="{'width': i+'px'}" animated class="shimmer"></ion-skeleton-text>
        </div>
        <div class="tag no-border"></div>
        <div class="tag no-border"></div>
        <div class="tag no-border"></div>
      </div>
    </div>
  </div>
</div>
