<div class="container" *ngIf="urlImage || urlVideo || urlFile">
  <div class="ctn-image" *ngIf="urlImage">
    <ion-icon class="icon-close" name="close-outline" (click)="cancel()"></ion-icon>
    <ion-img class="image" [src]="urlImage"></ion-img>
  </div>

  <div class="ctn-video" *ngIf="urlVideo">
    <ion-icon class="icon-close" name="close-outline" (click)="cancel()"></ion-icon>
    <div class="center-video">
      <video #video class="video" playsinline>
        <source [src]="urlVideo" type="video/mp4">
      </video>
      <img *ngIf="statusPlayPreview === 'play'" class="img-play" (click)="playPausePreview()"
           style="position: absolute; cursor: pointer" src="assets/icon/pause-button.svg" alt="">
      <img *ngIf="statusPlayPreview === 'pause'" class="img-play" (click)="playPausePreview()"
           style="position: absolute; cursor: pointer" src="assets/icon/play-button.svg" alt="">
    </div>
  </div>

  <div class="ctn-file" *ngIf="urlFile">
    <ion-icon class="icon-close-file" name="close-outline" (click)="cancel()"></ion-icon>
    <div class="center-file">
      <div>
        <ion-icon class="icon-file" name="document"></ion-icon>
      </div>
      <div>{{urlFile?.blob?.name}}</div>
    </div>
  </div>
</div>


