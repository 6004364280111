import { Component } from '@angular/core';
import {ModalController, NavController} from '@ionic/angular';

@Component({
  selector: 'app-custom-amount',
  templateUrl: './custom-amount.page.html',
  styleUrls: ['./custom-amount.page.scss'],
})


export class CustomAmountPage {

   value = '1';

  constructor(
    private navCtrl: NavController,
    public modalCtrl: ModalController,
  ) {
  }

  goBack() {
    this.modalCtrl.dismiss();
  }

  onConfirmValue() {
    this.modalCtrl.dismiss({value:this.value});
  }
}
