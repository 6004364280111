import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  collectionName = 'chats';

  constructor(private firestore: AngularFirestore) {}

  getByUserIdAndUserReference(userId: string, userReferenceId: string) {
    return this.firestore
      .collection(this.collectionName)
      .ref.where('user', '==', userId)
      .where('userReference', '==', userReferenceId)
      .get();
  }

  getByUserId(userId) {
    return this.firestore
      .collection(this.collectionName)
      .ref.where('user', '==', userId)
      .get();
  }

  subscribeChatList(userId): Observable<any[]> {
    return Observable.create((observer) => {
      try {
        this.firestore
          .collection(this.collectionName)
          .ref.where('user', '==', userId)
          .onSnapshot((values: any) => {
            const result = [];

            for (const value of values.docChanges()) {
              const object = {
                id: value.doc.id,
                ...value.doc.data(),
              };

              result.push(object);
            }

            observer.next(result);
          });
      } catch (e) {
        console.log(e);
        // observer.next(null);
      }
    });
  }

  updateChat(data, chatid, id) {
    return this.firestore
      .collection('messagesTest')
      .doc(chatid)
      .collection('chatMessages')
      .doc(id)
      .update(data);
  }


}
