import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {DefaultUtil} from '../../utils/default.util';
import {FeedService} from '../../services/feed.service';
import {MediaService} from '../../services/media.service';
import {Feed} from '../../models/feed.model';
import {PlatformUtil} from '../../utils/platform.util';
import {GestureController} from '@ionic/angular';

interface ControlView {
  showPending: boolean;
  showVideo: boolean;
  showReupload: boolean;
}

declare let jwplayer: any;
// eslint-disable-next-line @typescript-eslint/naming-convention
declare let Clappr: any;

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss'],
})
export class VideoComponent implements AfterViewInit {
  @Input()
  public feed: Feed;

  @Input()
  public index: any;

  @Input()
  public isLoggedUser = false;

  @Input()
  public allowedReupload: boolean;

  @Input()
  public showProfileInfo: boolean;

  @Input()
  public origin: string;

  @Input()
  public defaultMuted = false;

  @Input()
  public loadOnView = true;

  @Output()
  reportVideoEvent = new EventEmitter<any>();

  @Output()
  onReupload = new EventEmitter<any>();

  @Output()
  onClickProfile = new EventEmitter<any>();

  public controlView: ControlView = {} as ControlView;

  public showAll = true;

  public renderProfile = false;

  showAllText = false;
  textDesc = 'more';
  textOptions = null;
  played = 0;
  countSetup = 0;
  jwplayerCore = null;
  clapprCore = null;
  isLoadVideo = false;
  showPlay = true;
  isIos = false;
  @ViewChild('controlPlayer', {read: ElementRef}) controlPlayer: ElementRef;

  constructor(public defaultUtil: DefaultUtil,
              private feedService: FeedService,
              private mediaService: MediaService,
              public platformUtil: PlatformUtil,
  ) {
    this.allowedReupload = false;
    this.setControlView(false, false, false);
    const maxLetters = this.platformUtil.isDesktop() ? 16 : 10;
    this.isIos = this.platformUtil.isIos();
    // eslint-disable-next-line max-len
    this.textOptions = this.defaultUtil.validTextMore('Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries', 3, maxLetters);
  }

  async ngAfterViewInit() {
    if (this.feed?.media?.url) {
      const isPending = !(await this.defaultUtil.validFileExist(this.feed?.media?.url));
      if (isPending) {
        this.actionPedingVideos();
      } else {
        this.setup();
      }
    } else {
      if (this.allowedReupload) {
        this.actionPedingSavedVideos();
      } else {
        this.setControlView(false, false, false);
      }
    }
  }

  async ngOnInit() {
    // if (this.feed?.media?.url) {
    //   const isPending = !(await this.defaultUtil.validFileExist(this.feed?.media?.url));
    //   if (isPending) {
    //     this.actionPedingVideos();
    //   } else {
    //     this.setup();
    //   }
    // } else {
    //   if (this.allowedReupload) {
    //     this.actionPedingSavedVideos();
    //   } else {
    //     this.setControlView(false, false, false);
    //   }
    // }
  }

  setTextMode() {
    if (this.showAllText) {
      this.textOptions.text = this.textOptions?.shortText;
      this.textDesc = 'more';
    } else {
      this.textDesc = 'less';
      this.textOptions.text = this.textOptions?.largeText;
    }
    if (!this.textOptions?.shortText) {
      this.textOptions.text = this.textOptions?.largeText;
    }
    this.showAllText = !this.showAllText;
  }

  async play() {
    this.isLoadVideo = true;
    if (this.jwplayerCore) {
      this.jwplayerCore.play();
    }
    if (this.clapprCore) {
      this.clapprCore.play();
    }
  }

  async setupClappr() {
    try {
      this.setControlView(false, true, false);
      await this.defaultUtil.timeout(2000);
      this.clapprCore = new Clappr.Player({
        source: this.feed?.media?.url,
        poster: this.feed?.media?.imageUrl || this.feed?.media?.url?.toString()?.replace('.mp4', '.jpg'),
        mute: false,
        loop: false,
        height: 500,
        width: '100%',
        controls: false,
        hideMediaControl: false,
        hideVolumeBar: false,
        playback: {
          playInline: true,
          controls: false,
        },
      });
      let elementVideo = document.getElementById(`video${this.index}`);
      if (!elementVideo) {
        await this.defaultUtil.timeout(2000);
        elementVideo = document.getElementById(`video${this.index}`);
      }
      this.clapprCore.attachTo(elementVideo);
      this.clapprCore?.getPlugin('poster')?.$playWrapper?.hide();
      // console.log('====================================');
      // console.log('elementVideo>>>>>>>>>>>>', this.index);
      // console.log('elementVideo>>>>>>>>>>>>', elementVideo);
      // console.log('this.feed?.media?.url>>>>>>>>>>>>', this.feed?.media?.url);
      this.clapprCore.on(Clappr.Events.PLAYER_PLAY, async () => {
        Array.from(document.querySelectorAll('.media-control')).forEach((el) => el?.remove());
        this.isLoadVideo = false;
        this.played++;
      });
      window.addEventListener('ionScrollStart', async e => {
        try {
          const visible = await this.isVisible(elementVideo);
          if (this.loadOnView) {
            if (visible) {
              if (this.played > 0) {
                this.clapprCore?.play();
              }
            } else {
              this.clapprCore?.pause();
            }
          }
        } catch {
        }
      }, false);
    } catch (e) {
      console.log('e>>>>>>>>>>>>', e);

      if (this.countSetup < 10) {
        this.setupClappr();
      }
      this.countSetup++;
      this.showPlay = false;
    }
  }


  async setupJwplayer() {
    try {
      this.showPlay = true;
      await this.defaultUtil.timeout(500);
      this.setControlView(false, true, false);
      const url = this.feed?.media?.url;
      const image = this.feed?.media?.imageUrl || this.feed?.media?.url?.toString()?.replace('.mp4', '.jpg');
      const file = this.isLoggedUser ? url : url.toString().replace('.mp4', '_preview.mp4');
      this.jwplayerCore = jwplayer(`video${this.index}`)?.setup({
        playlist: [
          {
            file,
            image,
            //title: 'this is a title',
            //description: 'this is description',
          },
        ],
        aspectratio: '9:12', //do not change without fully testing auto-play across all devices. Ask rod
        type: 'mp4',
        flashplayer: '//ssl.p.jwpcdn.com/player/v/8.13.8/jwplayer.flash.swf',
        mute: this.defaultMuted,
        ph: 1,
        pid: 'G8eKR6DW',
        repeat: true,
        autostart: !this.loadOnView,
        stretching: 'uniform',
      });
      // player.addButton(
      //   // eslint-disable-next-line max-len
      // eslint-disable-next-line max-len
      //   '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path id="flag" d="M65,56a.937.937,0,0,1-1-.857V33.936a1.264,1.264,0,0,1,.744-1.11A12.139,12.139,0,0,1,70,32a26.507,26.507,0,0,1,7.222,1.483A19.89,19.89,0,0,0,82,34.571a13.159,13.159,0,0,0,4.438-.777,1.288,1.288,0,0,1,1.059.085.937.937,0,0,1,.5.8V46.434a1.08,1.08,0,0,1-.75.981,15.574,15.574,0,0,1-5.25.871,29.946,29.946,0,0,1-5.4-.788,35.293,35.293,0,0,0-6.6-.926,12.059,12.059,0,0,0-4,.488v8.083A.937.937,0,0,1,65,56Z" transform="translate(-64 -32)" fill="#fff"/></svg>',
      //   'report video',
      //   () => {
      //     console.log('callback report video');
      //     this.reportVideo();
      //   },
      //   'report-button'
      // );

      this.jwplayerCore.on('ready', (event) => {
        this.renderProfile = true;
      });

      this.jwplayerCore.on('play', (event) => {
        this.isLoadVideo = false;
        this.played++;
      });

      this.jwplayerCore.on('viewable', (event) => {
        if (this.loadOnView) {
          if (event?.viewable) {
            if (this.played > 0) {
              this.jwplayerCore?.play();
            }
          } else {
            this.jwplayerCore?.pause();
          }
        }
      });

      this.jwplayerCore.on('mediaError', (event) => {
        // send video to convertion
        if (event?.code === 224003) {
          // send video to convertion
          console.log('mediaError >>>>>>>>>>>>>>>>>', event);
          this.validCrashVideo();
        }
      });
    } catch (e) {
      this.showPlay = false;
      if (this.countSetup < 30) {
        await this.defaultUtil.timeout(100);
        this.setupJwplayer();
      }
      this.countSetup++;
      console.log('setup error >>>>>>>>>>>>>>>>>', e);
    }
  }

  async setup() {
    if (this.isIos) {
      this.setupClappr();
      return;
    }
    this.setupJwplayer();
  }

  isVisible(domElement) {
    return new Promise(resolve => {
      const o = new IntersectionObserver(([entry]) => {
        resolve(entry.intersectionRatio === 1);
        o.disconnect();
      });
      o.observe(domElement);
    });
  }

  actionPedingVideos() {
    let count = 0;
    if (this.origin === 'home') {
      this.showAll = false;
    }
    this.setControlView(true, false, false);
    const maxCount = this.allowedReupload ? 30 : 60;
    const interval = setInterval(async () => {
      const isPending = !(await this.defaultUtil.validFileExist(this.feed?.media?.url));
      count++;
      if (!isPending) {
        await this.defaultUtil.timeout(200);
        this.showAll = true;
        this.setup();
        clearInterval(interval);
        return;
      }
      if (count === maxCount) {
        this.setControlView(false, false, this.allowedReupload);
        clearInterval(interval);
        return;
      }
    }, 2000);
  }

  actionPedingSavedVideos() {
    let hourAfterUpload = 0;
    // eslint-disable-next-line no-underscore-dangle
    if (this.feed?.createdAt._seconds && this.feed.createdAt._nanoseconds) {
      // eslint-disable-next-line no-underscore-dangle
      this.feed.createdAt = new Date(this.feed.createdAt._seconds * 1000 + this.feed.createdAt._nanoseconds / 1000000);
      hourAfterUpload = this.diffHours(
        new Date(this.feed?.createdAt),
        new Date(),
      );
    }
    if (hourAfterUpload > 1) {
      this.setControlView(false, false, true);
      return;
    }
    this.setControlView(true, false, false);
    try {
      let count = 0;
      const interval = setInterval(async () => {
        let feed: any = await this.feedService.getFeedById(this.feed?.id);
        feed = feed?.data();
        count++;
        if (feed?.media?.url) {
          this.feed.media.url = feed?.media?.url;
          await this.defaultUtil.timeout(200);
          this.actionPedingVideos();
          clearInterval(interval);
        }
      }, 2000);
    } catch (e) {
      console.log('e', e);
    }
  }

  reportVideo() {
    this.reportVideoEvent.emit();
  }

  async validCrashVideo() {
    const url = this.feed?.media?.url;
    const data: any = await this.mediaService.getConvertedVideo(url);
    if (data?.empty) {
      this.mediaService.setConvertedVideo({
        url,
        isConverted: false,
        createdAt: this.defaultUtil.getDefaultDateFirebase()
      });
    }
  }

  setControlView(pending: boolean, video: boolean, reupload: boolean) {
    this.controlView.showVideo = video;
    this.controlView.showPending = pending;
    this.controlView.showReupload = reupload;
  }

  onClickReupload() {
    this.onReupload.emit({feed: this.feed, index: this.index});
  }

  diffHours(dt2, dt1) {
    let diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= (60 * 60);
    return Math.abs(Math.round(diff));
  }

  clickProfile(userName) {
    this.onClickProfile.emit(userName);
  }
}
