import {Injectable} from '@angular/core';
import {PlatformUtil} from '../utils/platform.util';
import {NavigationExtras, Router} from '@angular/router';
import {UserService} from '../services/user.service';
import {AppVersion} from '@awesome-cordova-plugins/app-version/ngx';
import {DefaultUtil} from '../utils/default.util';
import {ConfigService} from '../services/config.service';
import semver from 'semver';

@Injectable({
  providedIn: 'root',
})

export class AppVersionHelper {

  constructor(private platformUtil: PlatformUtil,
              private router: Router,
              private userService: UserService,
              private appVersion: AppVersion,
              private defaultUtils: DefaultUtil,
              private configService: ConfigService
  ) {
    if (this.platformUtil.isCordova()) {
    }
  }

  async validAppVersion() {
    if (this.platformUtil.isCordova()) {
      const currentPlatform = this.platformUtil.isCordovaAndroid() ? 'android' : 'ios';
      const installedVersion = await this.getVersionNumber();
      const {storeVersion, enable} = await this.getVersionConfig(currentPlatform);
      if (enable) {
        if (semver.lt(installedVersion, storeVersion)) {
          this.showAppUpdateAlert();
        }
      }
    }
  }

  showAppUpdateAlert() {
    this.defaultUtils.alertDefault({
        header: 'App update available',
        message: 'Choose Ok to update!',
        buttons: [
          {
            text: 'OK',
            handler: () => {
              if (this.platformUtil.isCordovaAndroid()) {
                window.location.assign('https://play.google.com/store/apps/details?id=com.ionic.truly');
              return;
              }
              window.location.assign('https://apps.apple.com/br/app/truly-coaches-your-growth-app/id6450243496?l=en-GB');
              return;
            }
          }
        ],
        backdropDismiss: false,
      }
    );
  }

  getAppName() {
    return this.appVersion.getAppName() || null;
  }

  getPackageName() {
    return this.appVersion.getPackageName() || null;
  }

  getVersionCode() {
    return this.appVersion.getVersionCode() || null;
  }

  getVersionNumber() {
    return this.appVersion.getVersionNumber() || null;
  }

  async getVersionConfig(currentPlatform) {
    const response = (await this.configService.getStoreVersionApp())?.data();
    return {storeVersion: response?.value[currentPlatform], enable: response?.enable};
  }

  parseAppVersion(version) {
    const [majorVersion, minorVersion, patches] = version?.split('.');
    return `${majorVersion}${minorVersion}${patches}`;
  }

}
