import {ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {MediaRecorder} from 'extendable-media-recorder';
import {ModalController, NavParams} from '@ionic/angular';
import {DefaultUtil} from '../../utils/default.util';
import {PlatformUtil} from '../../utils/platform.util';
import {User} from '../../models/user';

@Component({
  selector: 'app-camera',
  templateUrl: './camera.page.html',
  styleUrls: ['./camera.page.scss'],
})

export class CameraPage implements OnInit {

  @Input()
  duration = 180;

  public isPlay: boolean;
  public isStop: boolean;
  public videoConstraint: any;
  public facingMode: string;
  public durationText: string;
  public originalDuration: number;
  public mediaStreamPreview: MediaStream;
  public mediaStreamVideo: MediaStream;
  public seconds: any;
  public generalInterval: any;
  public mediaRecorderVideo: any;
  public blobVideo: Blob;
  public thumbs: Array<any>;
  public statusPlayPreview: string;
  public stopWatchValue: number;
  public currentTime: number;
  public stopWatchAddClass: boolean;
  public openText: boolean;
  public stepCamera: number;
  public ask: string;
  public loadCamera: boolean;
  public dimensionPreview: any;
  public isMobile: boolean;
  public isCordova: boolean;

  @ViewChild('preview', {read: ElementRef}) preview: ElementRef;
  @ViewChild('video', {read: ElementRef}) video: ElementRef;
  @ViewChild('canvas', {read: ElementRef}) canvas: ElementRef;

  constructor(
    public modalCtrl: ModalController,
    public defaultUtil: DefaultUtil,
    public navParams: NavParams,
    public platformUtil: PlatformUtil,
    public changeDetectionRef: ChangeDetectorRef
  ) {
    this.isPlay = false;
    this.openText = false;
    this.isStop = false;
    this.facingMode = 'user';
    this.seconds = 59;
    this.currentTime = 0;
    this.durationText = this.setCountDown(this.duration);
    this.originalDuration = this.duration;
    this.thumbs = [];
    this.statusPlayPreview = 'pause';
    this.stopWatchAddClass = false;
    this.stopWatchValue = 0;
    this.stepCamera = 1;
    this.ask = this.navParams.get('ask');
    this.loadCamera = true;
    this.isMobile = this.platformUtil?.isMobile();

  }

  ngOnInit() {
    this.initPreview();
    this.isCordova = this.platformUtil.isCordova();
    // this.interactionHidden();
  }

  async setOpenText() {
    this.openText = !this.openText;
  }

  async stopWatchControl() {
    await this.stopWatchAnimation(3);
    await this.stopWatchAnimation(2);
    await this.stopWatchAnimation(1);
    this.stopWatchValue = 0;
  }

  async stopWatchAnimation(time) {
    this.stopWatchValue = time;
    this.stopWatchAddClass = true;
    await this.defaultUtil.timeout(1000);
    this.stopWatchAddClass = false;
    await this.defaultUtil.timeout(230);
  }

  playPausePreview() {
    if (this.statusPlayPreview === 'play') {
      this.statusPlayPreview = 'pause';
      this.video?.nativeElement?.pause();
    } else if (this.statusPlayPreview === 'pause') {
      this.statusPlayPreview = 'play';
      this.video?.nativeElement?.play();
    }
  }

  setStep(step) {
    this.stepCamera = step;
  }

  handleCamera() {
    if (!this.isPlay) {
      this.isPlay = !this.isPlay;
      this.initVideo();
    } else if (this.isPlay) {
      this.stop();
      this.isStop = true;
    }
  };

  initPreview() {
    this.videoConstraint = {
      facingMode: this.facingMode,
      width: {min: 1024, ideal: 1280, max: 1920},
      height: {min: 576, ideal: 720, max: 1080},
    };
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia({video: this.videoConstraint, audio: true}).then((mediaStream) => {
        this.mediaStreamPreview = mediaStream;
        this.setStream(this.preview.nativeElement, this.mediaStreamPreview);
        const elementTarget = this.preview.nativeElement.getBoundingClientRect();
        this.dimensionPreview = {
          height: `${parseInt(elementTarget?.height, 10)}px`,
          width: `${parseInt(elementTarget?.width, 10)}px`,
        };
        setTimeout(() => this.loadCamera = false, 1500);
      }).catch(e => {
        console.log('e >>>>>>>>>>>>>>>>', e);
      });
    }
  }


  async initVideo() {
    if (this.mediaStreamPreview) {
      this.mediaStreamPreview.getTracks().forEach(track => {
        track.stop();
      });
    }
    this.loadCamera = true;
    await this.defaultUtil.timeout(500);
    this.loadCamera = false;
    await this.stopWatchControl();
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia({video: this.videoConstraint, audio: true}).then((mediaStream) => {
        this.mediaStreamVideo = mediaStream;
        this.setStream(this.preview.nativeElement, this.mediaStreamVideo, true);
      }).then(() => this.processRecording(this.mediaStreamVideo)).then(recordedChunks => {
        this.blobVideo = new Blob(recordedChunks, {type: this.mediaRecorderVideo.mimeType});
        this.video.nativeElement.src = URL.createObjectURL(this.blobVideo);
      }).catch(e => {
        alert(e.toString());
      });
    }
  }

  async processRecording(stream) {
    try {
      this.mediaRecorderVideo = new MediaRecorder(stream);
      const data = [];
      this.mediaRecorderVideo.ondataavailable = (event) => {
        data.push(event.data);
      };

      this.mediaRecorderVideo.start();
      const stopped = new Promise((resolve, reject) => {
        this.mediaRecorderVideo.onstop = resolve;
        this.mediaRecorderVideo.onerror = event => reject(event.name);
      });
      const recorded = await this.timer(this.mediaRecorderVideo);
      return Promise.all([
        stopped,
        recorded
      ]).then(() => data);
    } catch (e) {
      alert(e.toString());
    }

  }

  stop() {
    this.setStep(2);
    if (this.mediaRecorderVideo?.state !== 'inactive') {
      this.mediaRecorderVideo.stop();
    }
    if (this.mediaStreamVideo) {
      this.mediaStreamVideo.getTracks().forEach(track => track.stop());
    }
    this.setDefaultThumb();
  }

  interactionHidden() {
    setInterval(() => {
      console.log('gg');
      this.changeDetectionRef.detectChanges();
    }, 1);
  }

  async timer(recorder) {
    let duration = 0;
    return new Promise(resolve => {
      this.generalInterval = setInterval(() => {
        this.duration--;
        duration++;
        this.currentTime = duration;
        this.captureImageBySecond(duration);
        if (this.isStop) {
          clearInterval(this.generalInterval);
          resolve(true);
          setTimeout(() => {
            if (recorder.state === 'recording') {
              recorder.stop();
              this.handleCamera();
            }
          }, 1000);
        }
        this.durationText = this.setCountDown(this.duration);
        if (this.duration === 0) {
          resolve(true);
          if (recorder.state === 'recording') {
            recorder.stop();
            this.handleCamera();
          }
          clearInterval(this.generalInterval);
        }
      }, 1000);
    });
  };

  cancel() {
    this.isPlay = false;
    this.isStop = false;
    this.duration = this.originalDuration;
    this.currentTime = 0;
    this.setStep(1);
    this.durationText = this.setCountDown(this.duration);
    this.thumbs = [];
    this.initPreview();
  }

  async accept() {
    const thumbSelected = this.thumbs.find(a => a.valid);
    const thumbBlob = await this.defaultUtil.base64ToBlob(thumbSelected.image, 'image/jpeg', 512);
    this.modalCtrl.dismiss({
      video: this.blobVideo,
      image: thumbBlob
    });
  }

  stopCamera() {
    if (this.mediaStreamPreview) {
      this.mediaStreamPreview.getTracks().forEach(track => {
        track.stop();
      });
    }
  }

  close() {
    this.stopCamera();
    this.modalCtrl.dismiss(null);
  }

  setStream(nativeElement: any, mediaStream: MediaStream, capture: boolean = false) {
    nativeElement.srcObject = mediaStream;
    nativeElement.muted = true;
    if (capture) {
      nativeElement.captureStream = nativeElement.captureStream || nativeElement.mozCaptureStream;
    }
  }

  switchCamera() {
    this.stopCamera();
    if (this.facingMode === 'user') {
      this.facingMode = 'environment';
    } else {
      this.facingMode = 'user';
    }
    this.initPreview();
  }

  captureImageBySecond(time) {
    const elementTarget = this.preview.nativeElement.getBoundingClientRect();
    this.preview.nativeElement.currentTime = time;
    this.canvas.nativeElement.width = parseInt(elementTarget?.width, 10);
    this.canvas.nativeElement.height = parseInt(elementTarget?.height, 10);
    // }
    this.canvas.nativeElement
      .getContext('2d')
      .drawImage(this.preview.nativeElement, 0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height);
    this.thumbs.push({
      valid: false,
      image: this.canvas.nativeElement.toDataURL('image/jpeg'),
      id: new Date().valueOf().toString()
    });
  }

  setThumb(thumb) {
    this.thumbs = this.thumbs.map(a => {
      a.valid = thumb.id === a.id;
      return a;
    });
    this.video.nativeElement.setAttribute('poster', this.thumbs.find(a => a.valid).image);
  }

  setDefaultThumb() {
    this.thumbs = this.thumbs.map((a, aIndex) => {
      a.valid = aIndex === 1;
      return a;
    });
    this.video.nativeElement.setAttribute('poster', this.thumbs.find(a => a.valid).image);
    if (this.platformUtil.isMobile()) {
      this.dimensionPreview = {
        height: `100%`,
        width: `100%`,
      };
    }
  }

  setCountDown(duration) {
    const minutes = Math.floor(duration / 60);
    const seconds = duration % 60;
    return `${minutes}:${seconds < 10 ? 0 : ''}${seconds}`;
  }

}
