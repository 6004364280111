import {Injectable} from '@angular/core';
import {PlatformUtil} from '../utils/platform.util';
import {NavigationExtras, Router} from '@angular/router';
import {AppVersion} from '@awesome-cordova-plugins/app-version/ngx';
import {DefaultUtil} from '../utils/default.util';
import {EmitEventService} from '../services/emit-event.service';
import {ModalOfflinePage} from '../pages/modal-offline/modal-offline.page';
import {ModalController} from '@ionic/angular';
import {Network} from '@ionic-native/network/ngx';

@Injectable({
  providedIn: 'root',
})

export class NetworkHelper {

  constructor(private platformUtil: PlatformUtil,
              private router: Router,
              private network: Network,
              private appVersion: AppVersion,
              private defaultUtils: DefaultUtil,
              private modalCtrl: ModalController,
              private emitService: EmitEventService
  ) {
  }

  initNetworkCheck() {
    if (this.platformUtil.isCordova()) {
      this.networkNative();
      return;
    }
    this.networkWeb();
  }

  networkWeb() {
    this.emitService.getChangePage()
      .subscribe((data) => {
        if (!navigator.onLine) {
          this.modalOffline();
        }
      });
  }

  networkNative() {
    this.network.onDisconnect().subscribe(()=>{
      this.modalOffline();
    });
  }

  async modalOffline() {
    try {
      const modal = await this.modalCtrl.create({
        component: ModalOfflinePage,
        backdropDismiss: false,
        showBackdrop: true,
        cssClass: 'offline-modal',
        mode: 'md',
      });
      await modal.present();
    } catch (e) {
      console.log('modalContestReport error', e);
    }
  }

}
