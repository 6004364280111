import {Component, Input, OnInit} from '@angular/core';
import {User} from '../../models/user';
import {ModalController} from '@ionic/angular';
import {WalletService} from '../../services/wallet.service';
import {ColorToast} from '../../enums/color-toast';
import {DefaultUtil} from '../../utils/default.util';
import {NavigationExtras, Router} from '@angular/router';

@Component({
  selector: 'app-modal-send-money',
  templateUrl: './modal-send-money.page.html',
  styleUrls: ['./modal-send-money.page.scss'],
})
export class ModalSendMoneyPage implements OnInit {

  @Input()
  userLogged?: User = undefined;

  @Input()
  userChat?: User = undefined;

  @Input()
  redirectUrl?: string = '';

  valueRange = 1;

  balance = 0;

  isLoad = false;

  constructor(private modalCtrl: ModalController,
              private walletService: WalletService,
              private defaultUtils: DefaultUtil,
              private router: Router) {
  }

  async ngOnInit() {
    this.isLoad = true;
    await this.getDepositValues();
    this.isLoad = false;
  }

  onIonChange(event: any) {
    this.valueRange = event?.detail?.value;
  }

  close(saveTip) {
    this.modalCtrl.dismiss(saveTip);
  }

  async createTransfer() {
    this.isLoad = true;
    if (this.balance < this.valueRange || this.balance === 0) {
      this.isLoad = false;
      this.defaultUtils.globalToast('Insufficient balance.', ColorToast.danger);
      const defaultUrl = window.location.href.split('/')[2];
      const navigationExtras: NavigationExtras = {
        queryParams: {
          redirectUrl: `http://${defaultUrl}${this.redirectUrl}`,
          backToTip: true
        },
      };

      this.router.navigate(['deposit'], navigationExtras);
      this.close(false);
      return;
    }
    const params = {
      sendById: this.userLogged?.id,
      sendToId: this.userChat?.id,
      value: this.valueRange
    };
    const response = await this.walletService.createTransferTip(params);
    if (response.success) {
      this.defaultUtils.globalToast('Success', ColorToast.success, 2000);
      this.close(this.valueRange);
      this.isLoad = false;
    }

  }

  async getDepositValues() {
    const wallet: any = await this.walletService.getWalletByUser(
      this.userLogged.id
    );

    if (wallet.exists) {
      this.balance = parseInt(wallet?.data()?.depositBalance) || 0;
      console.log('this.balance', this.balance);
    }

  }


}
