import {Component, Input, OnInit} from '@angular/core';
import {ModalController, NavParams} from '@ionic/angular';

@Component({
  selector: 'app-modal-contest',
  templateUrl: './modal-contest.page.html',
  styleUrls: ['./modal-contest.page.scss'],
})
export class ModalContestPage implements OnInit {

  public reason: string;

  @Input()
  public name: string;

  @Input()
  public titleModal: string;

  @Input()
  public titleButton: string;

  @Input()
  public titleInput: string;


  constructor(
    private modalCtrl: ModalController,
    private navParams: NavParams,
  ) {
  }

  ngOnInit() {
  }

  close() {
    this.modalCtrl.dismiss(null);
  }

  confirm() {
    if(!this.reason){
      return null;
    }
    this.modalCtrl.dismiss({reason: this.reason});
  }
}
