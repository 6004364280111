import {Injectable} from '@angular/core';
import OneSignal, {NotificationClickEvent} from 'onesignal-cordova-plugin';

import {environment} from 'src/environments/environment';
import {PlatformUtil} from '../utils/platform.util';
import {NavigationExtras, Router} from '@angular/router';
import {UserService} from '../services/user.service';
import {OneSignal as OneSignalWeb} from 'onesignal-ngx';


interface Params {
  page: string;
  ref?: string;
}

enum Pages {
  profile = 'profile',
  myProfile = 'my-profile',
  chat = 'chat',
  feed = 'feed',
}

@Injectable({
  providedIn: 'root',
})
export class PushHelper {
  public oneSignalCore = OneSignal;

  constructor(
    private platformUtil: PlatformUtil,
    private oneSignalWeb: OneSignalWeb,
    private router: Router,
    private userService: UserService) {
  }

  async initOneSignal() {
    if (this.platformUtil.isCordova()) {
      this.setupOnesignal();
      return;
    }
    if (window.location.pathname.match('jointruly')) {
      this.setupOnesignalWeb();
    }
  }

  async setupOnesignalWeb() {
    try {
      await this.oneSignalWeb?.init(
        {
          appId: environment.oneSignalAppID,
        },
      );
      this.oneSignalWeb?.registerForPushNotifications();
      this.oneSignalWeb?.on('subscriptionChange', (isSubscribed) => {
        console.log('The user\'s subscription state is now:', isSubscribed);
      });
      this.oneSignalWeb?.on('notificationDisplay', (event) => {
        console.log('OneSignal notification displayed:', event);
        this.listenOpenNotificationsWeb();
      });

      this.listenOpenNotificationsWeb();

    } catch (e) {
      console.log('e>>>>>>>>> >', e);
    }
  }

  async listenOpenNotificationsWeb() {
    this.oneSignalWeb?.addListenerForNotificationOpened((event) => {
      console.log('addListenerForNotificationOpened:', event);
      this.redirectParams(event?.data);
    });
  }

  async setupOnesignal() {
    this.oneSignalCore.initialize(environment.oneSignalAppID);
    this.oneSignalCore.Location.setShared(false);
    const hasPermission = this.oneSignalCore.Notifications.hasPermission();
    if (!hasPermission) {
      this.oneSignalCore.Notifications.requestPermission(true).then((accepted: boolean) => {
        console.log('User accepted notifications ================ ' + accepted);
      });
    }
    this.oneSignalCore.Notifications.addEventListener('click', (data: NotificationClickEvent) => {
      this.redirectParams(data?.notification?.additionalData);
    });
    return;
  }


  setDataTag(data, key = '', value = '') {
    if (this.platformUtil.isCordova()) {
      this.oneSignalCore.User.addTags(data);
      return;
    }
    this.oneSignalWeb.sendTag(key, value);
  }

  addUserInfo(userName: any) {
    try {
      if (userName) {
        this.setDataTag({userName: userName?.toLowerCase()}, 'userName', userName?.toLowerCase());
      }
    } catch (e) {
      console.log('addUserInfoError', e);
    }
  }

  removeUserInfo() {
    if (this.platformUtil.isCordova()) {
      this.oneSignalCore.User.removeTag('userName');
      return;
    }
    this.oneSignalWeb.removeExternalUserId();
    this.oneSignalWeb.logoutEmail();
    this.oneSignalWeb.deleteTag('userName');
  }

  redirectParams(params: Params | any) {
    switch (params.page) {
      case Pages.profile:
        this.navigateToProfile(params?.ref);
        break;
      case Pages.myProfile:
        if (this.userService?.currentUser?.id) {
          this.navigateToMyProfile();
        }
        break;
      case Pages.chat:
        if (this.userService?.currentUser?.id) {
          this.navigateToChat(params?.ref);
        }
        break;
      case Pages.feed:
        this.navigateToWatch(params?.ref);
        break;
    }
  };

  navigateToChat(id) {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        id,
      },
    };
    this.router.navigate(['chat'], navigationExtras);
  }

  navigateToProfile(userName) {
    // eslint-disable-next-line
    if (userName == 'Truly') {
      return;
    }
    this.router.navigate(['/profile/' + userName]);
  }

  navigateToMyProfile() {
    this.router.navigate(['/my-profile']);
  }

  navigateToWatch(id) {
    this.router.navigateByUrl(`watch?ref=${id}`);
  }

}
