import {Component, HostListener, Input, OnInit, ViewChild} from '@angular/core';
import {NavigationExtras, Router} from '@angular/router';
import {IonContent, LoadingController, ModalController} from '@ionic/angular';
import {User} from 'src/app/models/user';
import {DefaultUtil} from '../../utils/default.util';
import {WalletService} from '../../services/wallet.service';
import {CalendlyService} from '../../services/calendly.service';
import {UserService} from '../../services/user.service';
import {AskService} from '../../services/ask.service';

// eslint-disable-next-line @typescript-eslint/naming-convention
export {};
declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Calendly: any;
  }
}


enum EventCalendly {
  viewed = 'calendly.event_type_viewed',
  dateTimeSelected = 'calendly.date_and_time_selected',
  scheduled = 'calendly.event_scheduled'
}

@Component({
  selector: 'app-modal-send-request-session',
  templateUrl: './modal-send-request-session.page.html',
  styleUrls: ['./modal-send-request-session.page.scss'],
})


export class ModalSendRequestSessionPage implements OnInit {

  // @ViewChild('contentChat', {static: false}) contentSendAsk: IonContent;
  @ViewChild('contentSendRequestSession', {static: false}) contentSendRequestSession: IonContent;

  @Input()
  selectedUser = {} as any;

  @Input()
  isChatPage = false;

  @Input()
  externalAsk = false;

  @Input()
  fromProfile = false;

  @Input()
  unloggedUser = false;

  @Input()
  redirectUrl = null;

  isload = false;

  alertShowed = false;

  currentUser: User = null;

  releaseEventSelectedCount = 0;

  releaseEventScheduledCount = 0;

  calendlyUserEvent = null;

  constructor(private modalCtrl: ModalController,
              private defaultUtils: DefaultUtil,
              private walletService: WalletService,
              private router: Router,
              private calendlyService: CalendlyService,
              private userService: UserService,
              private askService: AskService,
              private loadingCtrl: LoadingController,
  ) {
  }

  async ngOnInit() {
    this.isload = true;
    this.currentUser = await this.defaultUtils.getUser();
    this.selectedUser = (await this.userService.getUserByUsername(this.selectedUser?.name)).docs[0]?.data();
    await this.refreshCalendlyToken();
    this.calendlyUserEvent = await this.getUserCalendlyEvents();
    if (this.calendlyUserEvent) {
      await this.getShareSchedule();
      return;
    }
    this.alertErroCalendar('This user temporarily deactivated the calendar!');
  }

 async validUserHasCalendly(){
    this.selectedUser = (await this.userService.getUserByUsername(this.selectedUser?.name)).docs[0]?.data();
    if (!this.selectedUser?.calendlyInfo?.organization) {
      const alertCalendlyInfo = {
        message: 'This coach has not integrated their calendar yet. Please ask them to complete this step.',
        buttons: [
          {
            text: 'OK',
            role: 'cancel',
            handler: () => this.closeModal()
          },
        ]
      };
      await this.defaultUtils.alertDefault(alertCalendlyInfo);
      return;
    }
  }

  async refreshCalendlyToken() {
    const tokenInfo: any = await this.calendlyService?.getRefreshToken(this.selectedUser?.calendlyInfo?.refreshToken);
    if (tokenInfo) {
      this.selectedUser.calendlyInfo.refreshToken = tokenInfo?.refresh_token;
      this.selectedUser.calendlyInfo.accessToken = tokenInfo?.access_token;
      await this.updateUser(this.selectedUser);
    }
  }

  async updateUser(data) {
    return await this.userService.update(
      this.selectedUser.id,
      data
    );
  }

  async getUserCalendlyEvents() {
    const eventData: any = await this.calendlyService.getEventTypes({
      user: this.selectedUser.calendlyInfo?.owner,
      organization: this.selectedUser.calendlyInfo?.organization,
      token: this.selectedUser.calendlyInfo?.accessToken,
    });
    return eventData?.collection?.find(a => a?.active);
  }

  initCalendly(url) {
    window.Calendly.initInlineWidget({
      // url: 'https://calendly.com/diogomoura10/truly?hide_event_type_details=1&hide_gdpr_banner=1',
      url: `${url}?hide_event_type_details=1&hide_gdpr_banner=1`,
      parentElement: document.querySelector('.calendly-inline-widget'),
    });
    this.validCalendlyRender();
    // window.addEventListener('message',(e)=> this.controlEvents(e), false);
  }

  validCalendlyRender() {
    let duration = 10;
    const interval = setInterval(() => {
      duration--;
      if (duration === 0 && this.isload) {
        clearInterval(interval);
        this.alertErroCalendar('This user temporarily deactivated the calendar!');
      }
      if (!this.isload) {
        clearInterval(interval);
      }
    }, 1000);
  }

  alertErroCalendar(message) {
    this.defaultUtils.alertDefault({
        message,
        buttons: [
          {
            text: 'OK',
            handler: () => this.closeModal()
          }
        ],
        backdropDismiss: false,
      }
    );
  }

  async confirmAlert() {
    this.alertShowed = false;
    const isValidBalance = await this.verifyBalance(this.selectedUser?.price?.value || 0);
    if (isValidBalance) {
      return;
    }
    this.noMoneyAlert();
  }

  async verifyBalance(value) {
    let wallet: any = (await this.walletService.getWalletByUser(
      this.currentUser.id
    ));

    if (!wallet.exists) {
      return false;
    }
    wallet = wallet?.data();
    return parseInt(wallet.depositBalance) >= value;
  }

  noMoneyAlert() {
    this.defaultUtils.alertDefault({
        header: 'Insufficient Balance',
        message: '',
        buttons: [
          {
            text: 'Cancel Session',
            role: 'cancel',
            handler: () => this.closeModal()
          },
          {
            text: 'Add Credits',
            handler: () => this.goToDeposit()
          }
        ],
        backdropDismiss: false,
      }
    );
  }

  async goToDeposit() {
    await this.closeModal();
    const defaultUrl = window.location.href.split('/')[2];
    const navigationExtras: NavigationExtras = {
      queryParams: {
        redirectUrl: `http://${defaultUrl}${this.redirectUrl}`,
        backToSendSession: this.isChatPage,
      },
    };
    this.router.navigate(['deposit'], navigationExtras);
  }

  @HostListener('window:message', ['$event'])
  controlEvents(e) {
    if (this.isCalendlyEvent(e)) {
      const {event, payload} = e?.data;
      switch (event) {
        case EventCalendly.viewed:
          this.isload = false;
          this.releaseEventSelectedCount = 0;
          this.releaseEventScheduledCount = 0;
          this.contentSendRequestSession.scrollToTop(500);
          break;
        case EventCalendly.dateTimeSelected:
          if (this.releaseEventSelectedCount === 0) {
            this.releaseEventSelectedCount++;
            this.confirmAlert();
            this.contentSendRequestSession.scrollToBottom(500);
            //this.agreedAlert();
          }
          break;
        case EventCalendly.scheduled:
          if (this.releaseEventScheduledCount === 0) {
            this.releaseEventScheduledCount++;
            if (payload?.event?.uri) {
              this.sendRequest(payload?.event?.uri);
            }
          }
          break;
      }
    }
  };


  isCalendlyEvent(e) {
    return e.origin === 'https://calendly.com' && e.data.event && e.data.event.indexOf('calendly.') === 0;
  };

  async getUserAvailability() {
    const availabilitiesResult: any =
      await this.calendlyService?.getUserAvailability(this.selectedUser?.calendlyInfo?.owner, this.selectedUser?.calendlyInfo?.accessToken);
    const availability = availabilitiesResult?.collection?.find(a => a?.default);
    const hasValidSchedule = availability?.rules?.some(a => a?.intervals?.length);
    if (hasValidSchedule) {
      return {
        rules: availability?.rules,
        timezone: availability?.timezone
      };
    }
  }


  async getShareSchedule() {
    const availabilityRule = await this.getUserAvailability();
    if (!availabilityRule?.rules) {
      this.alertErroCalendar('This user doesn’t have any available time slots.');
      return;
    }
    const date = new Date();
    date.setDate(date.getDate() + 1);
    const tomorrowDate = date.toISOString().slice(0, 10);
    const data = {
      event_type: this.calendlyUserEvent?.uri,
      name: `Meeting ${this.currentUser?.name} and ${this.selectedUser?.name}`,
      duration: 60,
      period_type: 'available_moving',
      start_date: tomorrowDate,
      max_booking_time: 100000000,
      hide_location: false,
      location_configurations: [
        {
          kind: 'google_conference'
        }
      ],
      availability_rule: availabilityRule
    };
    const response: any = await this.calendlyService.createScheduleShare(data, this.selectedUser?.calendlyInfo?.accessToken);
    if (response) {
      const ref: any = this.defaultUtils.getFirstElement(response?.resource?.scheduling_links);
      this.initCalendly(ref.booking_url);
    } else {
      this.alertErroCalendar('This user temporarily deactivated the calendar!');
    }
  }

  async sendRequest(url) {
    let loading = null;
    try {
      loading = await this.loadingCtrl.create({message: 'Loading...'});
      loading.present();
      const response: any = await this.calendlyService.getEventInfo({
        url,
        token: this.selectedUser?.calendlyInfo?.accessToken
      });
      const startAt = new Date(response?.resource?.start_time)?.valueOf();
      const endAt = new Date(response?.resource?.end_time)?.valueOf();
      const joinUrl = response?.resource?.location?.join_url;
      const scheduleUri = response?.resource?.uri;
      const object: any = {
        chatId: '',
        messageId: `${this.currentUser.id}X${Date.now()}`,
        readed: false,
        updatedAt: this.defaultUtils.getDefaultDateFirebase(),
        createdAt: this.defaultUtils.getDefaultDateFirebase(),
        timestamp: new Date().getTime(),
        timezone: `${Intl.DateTimeFormat().resolvedOptions().timeZone}`,
        unloggedAsk: false,
        type: 'video-call',
        videoCall: {
          amount: this.selectedUser?.price?.value || 0,
          status: 'sent',
          scheduleUri,
          joinUrl,
          startAt,
          endAt,
        },
        sendBy: {
          id: this.currentUser.id,
          name: this.currentUser.name,
          photo: this.currentUser.photoURL,
          email: this.currentUser.email,
        },
        sendTo: {
          id: this.selectedUser.id,
          name: this.selectedUser.name,
          email: this.selectedUser.email,
          photo: this.selectedUser.photoURL,
        },
      };
      const result = await this.askService.sendSession(object);
      loading?.dismiss();
      if (!this.isChatPage) {
        this.goToChatPage(this.selectedUser.id);
      }
      //this.closeModal();
      this.contentSendRequestSession.scrollToTop(500);
    } catch (e) {
      console.log('getEventInfo >>>>>>>', e);
    } finally {
      loading?.dismiss();
    }
  }

  goToChatPage(userId) {
    if (this.fromProfile) {
      this.defaultUtils.storage().set('fromProfile', true);
    }
    const navigationExtras: NavigationExtras = {
      queryParams: {
        id: userId,
      },
    };

    this.router.navigate(['chat'], navigationExtras);
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }


}

